import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import "../index.css";

const Footer = () => (
  <Box className="footer-wrapper p-5 text-center mt-5">
    <Box className="footer-logo mb-3">
      <img src={require("../../images/logo.png")} width="120" />
    </Box>
    <Typography className="mb-3 light-grey-color-02">
      Technical Partner for Control and Automation
    </Typography>
    {/* <Box className="mt-3 social-icon">
      <a href="#" className="mr-3" target="_blank">
        <img
          src={require("../../images/icon-linkedin.svg")}
          alt="linkedIn"
          title="linkedIn"
        />
      </a>
      <a href="#" target="_blank">
        <img
          src={require("../../images/icon-facebook.svg")}
          alt="Facebook"
          title="Facebook"
        />
      </a>
    </Box> */}
  </Box>
);

export default connect()(Footer);
