export default {
  account: "Account",
  action: "Actie",
  accountNumber: "Account nummer",
  additionalDetails: "Aanvullende gegevens",
  addSalesLine: "Toevoegen orderregel",
  addAttachments: "Toevoegen bijlagen",
  addressName: "Adres naam (verplicht)",
  addressNameValidation: "* Adres naam is verplicht",
  addressLine1Validation: "* Adres Lijn 1 is verplicht",
  addressLine2Validation: "* Adres Lijn 2 is verplicht",
  addressLine3Validation: "* Adres Lijn 3 is verplicht",
  addressPostCodeValidation: "* Postcode is verplicht",
  businessPhone: "Zakelijk nummer",
  cancel: "Annuleer",
  cases: "Cases",
  caseType: "Case type (verplicht)",
  caseTypeValidation: "* Case type is verplicht",
  caseCreationSuccess: "Case succesvol aangemaakt",
  caseUpdationSuccess: "Case update succesvol",
  caseCancellationSuccess: "Case succesvol geannuleerd",
  cancelCase: "Annuleer case",
  changePassword: "Wijzig wachtwoord",
  contact: "Contact",
  country: "Land",
  collectionAddress: "Selecteer afhaal adres (verplicht)",
  collectionAddressNameValidation: "* Afhaal adres naam is verplicht",
  collectionAddressValidation: "* Afhaal adres is verplicht",
  collectionAddressNotFound:
    "Indien afhaaladres niet gevonden, klik hiernaast op 'schakelaar'",
  completeSurvey: "Vul vragenlijst in",
  columns: "Kolommen",
  confirmPassword: "Bevestig wachtwoord",
  conditionOfGoods: "conditie van goederen (verplicht)",
  conditionOfGoodsValidation: "* conditie van goederen is verplicht",
  createAccount: "MAAK EEN ACCOUNT AAN",
  createdOn: "Gemaakt op",
  create: "Aanmaken",
  createCase: "Maak nieuwe case aan",
  createCaseInfo:
    "Voeg de gevraagde gegevens toe en klik op Aanmaken om een nieuwe case aan te maken.",
  currentPassword: "Huidig wachtwoord",
  description: "Omschrijving",
  descriptionValidation: "* Extra details is verplicht ",
  deliveryAddress: "Selecteer afleveradres (verplicht)",
  deliveryAddressNameValidation: "* Afleveradres naam is verplicht",
  deliveryAddressValidation: "* Afleveradres is verplicht",
  deliveryAddressNotFound:
    "Indien afleveradres niet gevonden, selecteer dan schakelaar",
  edit: "Bewerken",
  emailAddress: "Email adres",
  emailAlreadyExistMsg: "* Email adres bestaat al",
  emailValidationMsg: "Ongeldig email adres",
  existingsignIn: "Bestaande gebruiker? Inloggen",
  faq: "FAQ",
  failed: "Mislukt",
  faultDetails: "Defect details (verplicht)",
  faultValidation: "* Defect details is verplicht",
  firstName: "Voornaam",
  forgetPassword: "Wachtwoord vergeten",
  forgetPasswordLinkSuccess: "Activatielink is naar uw email adres gestuurd",
  home: "Home",
  jobTitle: "Functie",
  lastName: "Achternaam",
  logOut: "Uitloggen",
  myProfile: "Mijn Account",
  myDetails: "Mijn gegevens",
  mobilePhone: "Mobiel nummer",
  mobileValidationMsg: "Ongeldig mobiel nummer",
  newHere: "Het lijkt erop dat u nieuw bent!",
  newPassword: "Nieuw wachtwoord",
  newToPortal: "Nieuwe gebruiker?",
  noOrdersFound:
    "* Er zijn geen orderregels gevonden die geschikt zijn voor retour",
  noOrderAdded:
    "* Verkooporder(regels) moet toegevoegd worden. Selecteer de verkooporderregel en klik vervolgens op de groene knop 'Toevoegen orderregel' om dit proces te voltooien",
  noSurvey: "Geen vragenlijst",
  no: "Nee",
  orderNumber: "Order nummer",
  partNumber: "Besteld artikelnummer",
  partNumberReceieved: "Ontvangen artikelnummer",
  password: "Wachtwoord",
  passwordUpdateSuccess:
    "Wachtwoord met succes aangepast, log a.u.b. met nieuw wachtwoord in",
  passwordUpdateFail:
    "Wachtwoord aanpassing mislukt, probeer het later nog eens.",
  passwordMismatch: "Wachtwoorden komen niet overeen.",
  phone: "Telefoon",
  phoneValidationMsg: "Ongeldig telefoonnummer",
  pincode: "Pincode (verplicht)",
  profileUpdateSuccess: "Profiel aanpassing succesvol",
  profileUpdateFail: "Fout in update profiel",
  profileVerificationSuccess: "Verificatie email is succesvol verzonden",
  profileVerificationFail: "Fout in verzenden verificatie link",
  passwordPolicyMessage:
    "* Wachtwoord moet 8 karakters bevatten, waarvan minimaal 1 hoofdletter, 1 speciaal teken en 1 nummer",
  product: "Product",
  quantity: "Hoeveelheid besteld",
  quantityValidation:
    "Retour hoeveelheid moet minder zijn dan bestelde hoeveelheid",
  returnReason: "Reden retour (verplicht)",
  returnReasonValidation: "* Retourreden is verplicht",
  receivedQty: "Ontvangen hoeveelheid",
  returnQty: "Hoeveelheid to Retour",
  reason: "Reden",
  resetPasswordfailed: "Herstel wachtwoord mislukt, controleer email adres",
  replacementRequired: "Vervanging nodig? (verplicht)",
  requestAnUpdate: "Vraag update aan",
  requestAnUpdateSubject: "Klantverzoek om update",
  returnTerms: "Ik aanvaard het Routeco retourbeleid",
  rigisterFailed:
    "System error. Registratie mislukt. Neem contact op met verkoopafdeling. customer.service@routeco.com",
  rigisterSuccess: "Registratie met succes afgerond",
  seriesLetter: "Serie letter (verplicht)",
  seriesValidation: "* Serie letter is verplicht",
  salesOrder: "Verkoop order",
  sendVerifyMail: "Verstuur verificatie email",
  selectOrderLine: "Selecteer product",
  selectOrderLineHelp:
    "Selecteer elk product en hoeveelheid welke u retour wil sturen, klik vervolgens op toevoegen orderregel.",
  selectedOrder: "Geselecteerde order",
  search: "Zoeken",
  searchOrder: "Voer order nummer in (verplicht)",
  searchOrderHelpText:
    "Voer verkooporder of inkoopreferentie in. Verkooporders kunnen opgezocht worden via verkooporder nummer of inkoopreferentie.",
  sites: "Sites",
  signin: "Aanmelden",
  signUp: "Inschrijven",
  signUpDetails:
    "Door registratie op ons klantenservice portaal kunt u alle cases inzien, nieuwe cases aanmaken en de status hiervan monitoren.",
  signIntoAccount: "Inloggen in account!",
  signInfailed: "ongeldige gebruikersnaam of wachtwoord.",
  street1: "Straat 1 (verplicht)",
  street2: "Straat 2",
  street3: "Straat 3",
  status: "Status",
  surveys: "Vragenlijsten",
  submit: "Verzenden",
  suffix: "Suffix (verplicht)",
  suffixValidation: "* Suffix is verplicht",
  ticketNumber: "Case nummer",
  type: "Type",
  update: "Bewerken",
  updateCase: "Bewerken case",
  updatePassword: "Bewerken wachtwoord",
  verifySuccess: "Verificatie afgerond.",
  verifyFailed: "Verificatie mislukt.",
  yes: "Ja",
};
