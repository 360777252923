import { Backdrop, Box } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { ActivateContact } from "../../services/CustomerService";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState } from "../../types/Master";
import Defines from "../../config";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  translation: state.translation,
});

const mapDispatchToProps = (dispatch: React.Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const Verification = (props: any) => {
  const { isLoading, setLoader, setToastMessage, translation } = props;
  const [isRedirect, setIsRedirect] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    setLoader(true);
    let contactid = new URLSearchParams(search).get("contactId");
    let activationid = new URLSearchParams(search).get("activationId");
    let activationurl = Defines.VERIFY_URL + search;
    ActivateContact(contactid, activationid, activationurl)
      .then((res) => {
        setLoader(false);
        setToastMessage(translation.verifySuccess, true);
        setIsRedirect(true);
      })
      .catch((ex) => {
        setLoader(false);
        setToastMessage(translation.verifyFailed, false);
        setIsRedirect(true);
      });
  }, [search]);

  if (isRedirect) {
    return <Redirect to="/SignIn" />;
  }

  return (
    <Box className="d-flex left-side-wrapper row my-4">
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <Box className="loading-icon" />
      </Backdrop>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
