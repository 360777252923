import { store } from "../App";
import {
  article,
  Case,
  CaseSubType,
  CaseType,
  ConditionOfGoods,
  CustomerAddress,
  OpCoCountry,
  Order,
  OrderLine,
  Survey,
  User,
} from "../types/Master";

export const GetLoggedInUser = (): User | undefined => {
  if (localStorage.getItem("user") !== null) {
    return JSON.parse(localStorage.getItem("user") || "");
  } else return undefined;
};

export const GetToken = (): string => {
  if (localStorage.getItem("token") !== null) {
    return JSON.parse(localStorage.getItem("token") || "");
  } else return "";
};

const unauthorized = "Unauthorized";
const handleUnauthorized = (message: string) => {
  store.dispatch({ type: "LOGOUT", payload: undefined });
  throw new Error(message);
};

export const CustomerSignIn = (
  userId: any,
  password: any
): Promise<{ user: User; token: string; message: string } | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/SignIn", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          let data = JSON.parse(response.data);
          if (data != null) {
            resolve({
              user: data,
              token: response.token,
              message: response.message,
            });
          } else reject("Login failed");
        } else reject("Login failed");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const ForgotPassword = (userId: any): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/ForgotPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.status == "success") resolve("success");
        else reject("failed");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const VerifyUser = (
  UserId: any,
  pwdresetId: any,
  requrl: any,
  Password: any
): Promise<{ user: User; token: string } | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/VerifyUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ UserId, Password, pwdresetId, requrl }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null && response.status == "success")
          resolve({ user: JSON.parse(response.data), token: response.token });
        else reject("Login faied");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreatePassword = (
  userId: any,
  password: any
): Promise<{ user: User; token: string } | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/CreatePassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null && response.status == "success")
          resolve({ user: JSON.parse(response.data), token: response.token });
        else reject("Login faied");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const ActivateContact = (
  contactId: any,
  activationId: any,
  activationUrl: any
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch(`api/Customer/ActivateContact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contactId, activationId, activationUrl }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(response.status == "success");
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CustomerSignUp = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/Register", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const ChangePassword = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  data.forEach((item, key) => {
    console.log(`${key} - ${item}`);
  });
  return new Promise((resolve, reject) => {
    fetch("api/Customer/ChangePassword", {
      method: "POST",
      headers: new Headers({
        Authorization: GetToken(),
      }),
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateContact = (
  ID: string,
  FirstName: string,
  LastName: string,
  Email: string,
  Phone: string
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/UpdateContact", {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
      body: JSON.stringify({
        ID,
        FirstName,
        LastName,
        Email,
        Phone,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCases = (accountId: string): Promise<Case[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/GetCases?accountId=" + accountId, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCaseDetails = (
  accountId: string,
  ticketNumber: string
): Promise<Case | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Cases/GetCaseDetails?accountId=" +
        accountId +
        "&ticketNumber=" +
        ticketNumber,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: GetToken(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateNewCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/CreateIncident", {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/UpdateIncident", {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const RequestUpdateCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/CreateTask", {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetArticles = (): Promise<article[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Articles/GetArticles", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetSurveys = (uid: string): Promise<Survey[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Survey/GetSurveys?userId=" + uid, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CancelCase = (
  data: FormData
): Promise<{ status: string; message: string }> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/CancelIncident", {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetSearchOrderDetails = (
  accountId: string,
  orderId: string,
  type: string
): Promise<OrderLine[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Orders/GetSearchOrderDetails?accountId=" +
        accountId +
        "&orderId=" +
        orderId +
        "&type=" +
        type,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: GetToken(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCaseTypes = (): Promise<CaseType[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/GetCaseTypes", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCaseSubTypes = (): Promise<CaseSubType[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/GetCaseSubTypes", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetAddresses = (
  accountId: string
): Promise<CustomerAddress[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(`api/Account/GetAddresses?accountId=${accountId}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const ResendVerification = (
  userId: string
): Promise<{
  status: string;
  message: string;
}> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/ResendVerification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve({ status: response.status, message: response.message });
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetUserVerificationStatus = (
  userId: any,
  selectedLanguage: string
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Customer/GetUserVerificationStatus?userId=" +
        userId +
        "&selectedLanguage=" +
        selectedLanguage,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: GetToken(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.message != null)
          resolve(response.message);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetConditionOfGoodsOptions = (): Promise<
  ConditionOfGoods[] | undefined
> => {
  return new Promise((resolve, reject) => {
    fetch("api/Cases/GetConditionOfGoodsOptions", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCountries = (): Promise<OpCoCountry[] | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(`api/Customer/GetCountries`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null)
          resolve(JSON.parse(response.data));
        else if (response.message == unauthorized)
          handleUnauthorized(response.message);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};
