export default {
  account: "Konto",
  action: "Aktion",
  accountNumber: "Kontonummer",
  additionalDetails: "Zusätzliche Angaben eintragen",
  addSalesLine: "Auftragszeile hinzufügen",
  addAttachments: "Anhänge hinzufügen",
  addressName: "Adressbezeichnung (erforderlich)",
  addressNameValidation: "* Adressbezeichnung ist erforderlich",
  addressLine1Validation: "* Adresszeile1 ist erforderlich",
  addressLine2Validation: "* Adresszeile2 ist erforderlich",
  addressLine3Validation: "* Adresszeile3 ist erforderlich",
  addressPostCodeValidation: "* Postleitzahl ist erforderlich",
  businessPhone: "Geschäftliche Telefonnummer",
  cancel: "Abbrechen",
  cases: "Reklamationen",
  caseType: "Reklamationsart (erforderlich)",
  caseTypeValidation: "* Reklamationsart ist erforderlich",
  caseCreationSuccess: "Reklamation erfolgreich erstellt",
  caseUpdationSuccess: "Reklamation erfolgreich aktualisiert",
  caseCancellationSuccess: "Reklamation erfolgreich gelöscht",
  cancelCase: "Reklamation löschen",
  changePassword: "Passwort ändern",
  contact: "Kontakt",
  country: "Land",
  collectionAddress: "Abholadresse auswählen (erforderlich)",
  collectionAddressNameValidation:
    "* Bezeichnung der Abholadresse ist erforderlich",
  collectionAddressValidation: "* Abholadresse ist erforderlich",
  collectionAddressNotFound:
    "Wenn die Abholadresse nicht gefunden wurde, dann Umschalter aktivieren",
  completeSurvey: "Vollständige Übersicht",
  columns: "Spalten",
  confirmPassword: "Passwort bestätigen",
  conditionOfGoods: "Zustand der Waren (erforderlich)",
  conditionOfGoodsValidation: "* Zustand der Waren ist erforderlich",
  createAccount: "KONTO ERSTELLEN",
  createdOn: "Erstellt am",
  create: "Erstellen",
  createCase: "Neue Reklamation erstellen",
  createCaseInfo:
    "Bitte erforderliche Angaben hinzufügen und zum Erstellen einer neuen Reklamation auf Erstellen klicken.",
  currentPassword: "Aktuelles Passwort",
  description: "Beschreibung",
  descriptionValidation: "* Zusätzliche Angaben sind erforderlich",
  deliveryAddress: "Lieferadresse auswählen (erforderlich)",
  deliveryAddressNameValidation:
    "* Bezeichnung der Lieferadresse ist erforderlich",
  deliveryAddressValidation: "* Lieferadresse ist erforderlich",
  deliveryAddressNotFound:
    "Wenn die Lieferadresse nicht gefunden wurde, dann Umschalter aktivieren",
  edit: "Bearbeiten",
  emailAddress: "E-Mail-Adresse",
  emailAlreadyExistMsg: "* E-Mail bereits vorhanden",
  emailValidationMsg: "Ungültige E-Mail-Adresse",
  existingsignIn: "Existierender Benutzer? Sich anmelden",
  faq: "FAQ",
  failed: "Fehlgeschlagen",
  faultDetails: "Fehlerdetails (erforderlich)",
  faultValidation: "* Fehlerdetails sind erforderlich",
  firstName: "Vorname",
  forgetPassword: "Passwort vergessen",
  forgetPasswordLinkSuccess:
    "Der Aktivierungslink wurde an Ihre Mailadresse geschickt!",
  home: "Hauptseite",
  jobTitle: "Berufsbezeichnung",
  lastName: "Nachname",
  logOut: "Abmelden",
  myProfile: "Mein Konto",
  myDetails: "Meine Daten",
  mobilePhone: "Mobiltelefon",
  mobileValidationMsg: "Ungültige Mobilnummer",
  newHere: "Sie sind neu hier?",
  newPassword: "Neues Passwort",
  newToPortal: "Neu im Portal?",
  noOrdersFound: "* Keine Auftragszeilen für mögliche Retoure gefunden",
  noOrderAdded:
    "* Kundenauftrag(szeilen) sind hinzuzufügen. Wählen Sie bitte die Auftragszeile aus und klicken Sie dann auf die grüne Schaltfläche 'Auftragszeile hinzufügen' um diesen Vorgang abzuschließen",
  noSurvey: "Keine Übersicht",
  no: "Keine",
  orderNumber: "Auftragsnummer",
  partNumber: "Nummer des bestellten Artikels",
  partNumberReceieved: "Nummer des erhaltenen Artikels",
  password: "Passwort",
  passwordUpdateSuccess:
    "Ihr Passwort wurde erfolgreich aktualisiert. Bitte nochmals mit neuem Passwort anmelden.",
  passwordUpdateFail:
    "Passwortaktualisierung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.",
  passwordMismatch: "Die Passwörter stimmen nicht überein.",
  phone: "Telefon",
  phoneValidationMsg: "Ungültige Telefonnummer",
  pincode: "PIN-Code (erforderlich)",
  profileUpdateSuccess: "Profil erfolgreich aktualisiert",
  profileUpdateFail: "Fehler bei der Aktualisierung des Profils",
  profileVerificationSuccess: "Das Bestätigungsmail wurde erfolgreich versandt",
  profileVerificationFail: "Fehler beim Versenden des Bestätigungslinks",
  passwordPolicyMessage:
    "* Das Passwort muss aus 8 Zeichen bestehen, darunter mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Sonderzeichen und 1 Ziffer",
  product: "Produkt",
  quantity: "bestellte Menge",
  quantityValidation:
    "Die Retourenmenge sollte geringer sein als die Bestellmenge",
  returnReason: "Retourengrund (erforderlich)",
  returnReasonValidation: "* Retourengrund ist erforderlich",
  receivedQty: "Menge erhalten ",
  returnQty: "Zurückzugebende Menge",
  reason: "Grund",
  resetPasswordfailed:
    "Zurücksetzen des Passwortes fehlgeschlagen. Bitte rufen Sie Ihre E-Mails unter der eingetragenen E-Mail-Adresse ab.",
  replacementRequired: "Benötigen Sie einen Austausch? (Pflichtfeld)",
  requestAnUpdate: "Aktualisierung anfordern",
  requestAnUpdateSubject: "Kundenanforderung für Aktualisierung",
  returnTerms: "Akzeptierte Routeco-Rückgabebedingungen",
  rigisterFailed:
    "Systemfehler. Registrierung fehlgeschlagen. Bitte wenden Sie sich an den Routeco-Innendienst. customer.service@routeco.com",
  rigisterSuccess: "Registrierung erfolgreich abgeschlossen",
  seriesLetter: "Serienbrief (erforderlich)",
  seriesValidation: "* Serienbrief ist erforderlich",
  salesOrder: "Kundenauftrag",
  sendVerifyMail: "Bestätigungsmail senden",
  selectOrderLine: "Produkt auswählen",
  selectOrderLineHelp:
    "Jedes zur Rücksendung bestimmte Produkt und die jeweilige Menge auswählen, dann Button 'Auftragszeile hinzufügen' anklicken.",
  selectedOrder: "Ausgewählter Auftrag",
  search: "Suche",
  searchOrder: "Auftragsnummer eingeben (erforderlich)",
  searchOrderHelpText:
    "Kundenauftragsnummer oder Bestellnummer eingeben. Kundenaufträge können mit der Kundenauftragsnummer oder der Bestellnummer gesucht werden.",
  sites: "Sites",
  signin: "Anmelden",
  signUp: "Registrieren",
  signUpDetails:
    "Indem Sie ein Kundenkonto in unserem Kundenportal erstellen, haben Sie Zugriff auf all Ihre Reklamationen, können neue Reklamationen erstellen und den aktuellen Status verfolgen.",
  signIntoAccount: "Bei Konto anmelden",
  signInfailed: "Benutzername oder Passwort ungültig.",
  street1: "Straße 1 (erforderlich)",
  street2: "Straße 2",
  street3: "Straße 3",
  status: "Status",
  surveys: "Übersicht",
  submit: "Absenden",
  suffix: "Suffix (erforderlich)",
  suffixValidation: "* Suffix ist erforderlich",
  ticketNumber: "Reklamationsnummer",
  type: "Art",
  update: "Aktualisieren",
  updateCase: "Reklamation aktualisieren",
  updatePassword: "Passwort aktualisieren",
  verifySuccess: "Überprüfung abgeschlossen.",
  verifyFailed: "Überprüfung fehlgeschlagen.",
  yes: "Ja",
};
