import { SignInRequest, User } from "./Master";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const API_ERROR = "API_ERROR";
export const SET_LOADER = "SET_LOADER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SET_EDIT_CASE_NUMBER = "SET_EDIT_CASE_NUMBER";
export const SET_LANGUAGE = "SET_LANGUAGE";

interface Login {
  type: typeof LOGIN;
  payload: SignInRequest;
}
interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: { user: User; token: string };
}
interface ApiError {
  type: typeof API_ERROR;
  payload: any;
}
interface Logout {
  type: typeof LOGOUT;
  payload: any;
}
interface SetLoader {
  type: typeof SET_LOADER;
  payload: any;
}
interface SetEditCaseNumber {
  type: typeof SET_EDIT_CASE_NUMBER;
  payload: any;
}
interface UpdateUser {
  type: typeof UPDATE_USER;
  payload: any;
}
interface SetToastMessage {
  type: typeof SET_TOAST_MESSAGE;
  payload: { message: string; status: boolean };
}
interface SetNavigation {
  type: typeof SET_NAVIGATION;
  payload: any;
}
interface SetLanguage {
  type: typeof SET_LANGUAGE;
  payload: any;
}

export type action_types =
  | Login
  | Logout
  | LoginSuccess
  | ApiError
  | SetLoader
  | UpdateUser
  | SetNavigation
  | SetToastMessage
  | SetEditCaseNumber
  | SetLanguage;
