export default {
  account: "Compte",
  action: "Action",
  accountNumber: "Numéro de compte client",
  additionalDetails: "Entrez des détails supplémentaires",
  addSalesLine: "Ajouter une ligne de commande",
  addAttachments: "Ajouter des pièces jointes",
  addressName: "Nom de l'adresse (obligatoire)",
  addressNameValidation: "* Le nom de l'adresse est obligatoire",
  addressLine1Validation: "* La ligne d'adresse 1 est obligatoire",
  addressLine2Validation: "* La ligne d'adresse 2 est obligatoire",
  addressLine3Validation: "* La ligne d'adresse 3 est obligatoire",
  addressPostCodeValidation: "* Le code postal est obligatoire",
  businessPhone: "Téléphone professionnel",
  cancel: "Annuler",
  cases: "Cases",
  caseType: "Type de case (obligatoire)",
  caseTypeValidation: "* Le type de case est requis",
  caseCreationSuccess: "Case créé avec succès",
  caseUpdationSuccess: "Case mis à jour avec succès",
  caseCancellationSuccess: "Case annulé avec succès",
  cancelCase: "Annuler le case",
  changePassword: "Changer le mot de passe",
  contact: "Contact",
  country: "Pays",
  collectionAddress: "Sélectionnez l'adresse de collecte (obligatoire)",
  collectionAddressNameValidation:
    "* Le nom de l'adresse de collecte est obligatoire",
  collectionAddressValidation: "* L'adresse de collecte est obligatoire",
  collectionAddressNotFound:
    "Si l'adresse de collecte n'a pas été trouvée, sélectionnez << Oui >> pour entrer une nouvelle adresse",
  completeSurvey: "Compléter l'enquête",
  columns: "Colonnes",
  confirmPassword: "Confirmez le mot de passe",
  conditionOfGoods: "Condition des marchandises (obligatoire)",
  conditionOfGoodsValidation: "* L'état des marchandises est obligatoire",
  createAccount: "CRÉER UN COMPTE",
  createdOn: "Créé Le",
  create: "Créer",
  createCase: "Créer un nouveau case",
  createCaseInfo:
    "Veuillez ajouter les détails requis et cliquer sur enregistrer pour créer un nouveau case.",
  currentPassword: "Mot de passe actuel",
  description: "Description",
  descriptionValidation: "* Des détails supplémentaires sont obligatoire",
  deliveryAddress: "Sélectionnez l'adresse de livraison (obligatoire)",
  deliveryAddressNameValidation:
    "* Le nom de l'adresse de livraison est obligatoire",
  deliveryAddressValidation: "* L'adresse de livraison est obligatoire",
  deliveryAddressNotFound:
    "Si l'adresse de livraison n'a pas été trouvée, sélectionnez << Oui >> pour entrer une nouvelle adresse",
  edit: "Modifier",
  emailAddress: "Adresse e-mail",
  emailAlreadyExistMsg: "* L'e-mail existe déjà",
  emailValidationMsg: "Adresse e-mail incorrect",
  existingsignIn: "Utilisateur existant ? Connectez-vous",
  faq: "FAQ",
  failed: "Échoué",
  faultDetails: "Détails du défaut (obligatoire)",
  faultValidation: "* Les détails du défaut sont obligatoires",
  firstName: "Prénom",
  forgetPassword: "Mot de passe oublié",
  forgetPasswordLinkSuccess: "Lien d'activation envoyé à votre courrier !",
  home: "Page d'accueil",
  jobTitle: "Fonction",
  lastName: "Nom de famille",
  logOut: "Déconnexion",
  myProfile: "Mon compte",
  myDetails: "Mes coordonnées",
  mobilePhone: "Téléphone mobile",
  mobileValidationMsg: "Numéro de mobile incorrect",
  newHere: "On dirait que vous êtes nouveau ici !",
  newPassword: "Nouveau mot de passe",
  newToPortal: "Nouveau sur Portal ?",
  noOrdersFound:
    "* Aucune ligne de commande trouvée qui soit éligible pour un retour. Veuillez sélectionner la ligne de commande client, puis cliquez sur le bouton vert 'Ajouter une ligne de commande' pour terminer ce processus.",
  noOrderAdded: "* Il faut ajouter une ou plusieurs lignes de commande",
  noSurvey: "Pas d'enquête",
  no: "Non",
  orderNumber: "Numéro de commande",
  partNumber: "Référence de la pièce commandée",
  partNumberReceieved: "Référence de la pièce reçue",
  password: "Mot de passe",
  passwordUpdateSuccess:
    "Votre mot de passe a été mis à jour avec succès, veuillez vous connecter à nouveau avec un nouveau mot de passe.",
  passwordUpdateFail:
    "La mise à jour du mot de passe a échoué. Essayez à nouveau après un certain temps.",
  passwordMismatch: "Les mots de passe ne correspondent pas.",
  phone: "Téléphone",
  phoneValidationMsg: "Numéro de téléphone incorrect",
  pincode: "Code postal (obligatoire)",
  profileUpdateSuccess: "Profil mis à jour avec succès",
  profileUpdateFail: "Erreur dans la mise à jour du profil",
  profileVerificationSuccess:
    "L'email de vérification a été envoyé avec succès",
  profileVerificationFail: "Erreur dans l'envoi du lien de vérification",
  passwordPolicyMessage:
    "*  Le mot de passe doit contenir 8 caractères avec au moins 1 majuscule, 1 minuscule, 1 caractère spécial et 1 caractère numérique.",
  product: "Produit",
  quantity: "Quantité commandée",
  quantityValidation:
    "La quantité retournée doit être inférieure à la quantité commandée.",
  returnReason: "Raison du retour (obligatoire)",
  returnReasonValidation: "* Le motif de retour est obligatoire",
  receivedQty: "Quantité reçue",
  returnQty: "Quantité to retournée",
  reason: "Raison",
  resetPasswordfailed:
    "La réinitialisation du mot de passe a échoué, veuillez vérifier l'adresse électronique saisie.",
  replacementRequired: "Remplacement nécessaire ? (Obligatoire)",
  requestAnUpdate: "Demander une mise à jour",
  requestAnUpdateSubject: "Demande de mise à jour par le client",
  returnTerms: "Politique de retour Routeco acceptée",
  rigisterFailed:
    "Erreur système. Échec de l'enregistrement. Veuillez contacter le service client de Routeco. customer.service@routeco.com",
  rigisterSuccess: "Enregistrement réussi",
  seriesLetter: "Lettre de série (obligatoire)",
  seriesValidation: "* Une lettre de série est obligatoire",
  salesOrder: "Commande",
  sendVerifyMail: "Envoyer le courrier de vérification",
  selectOrderLine: "Sélectionnez un produit",
  selectOrderLineHelp:
    "Sélectionnez chaque produit et la quantité que vous souhaitez retourner, puis cliquez sur le bouton Ajouter une ligne de commande.",
  selectedOrder: "Commande sélectionnée",
  search: "Recherche",
  searchOrder: "Entrez le numéro de commande (obligatoire)",
  searchOrderHelpText:
    "Entrez le numéro de la commande de vente ou de la commande d'achat. Les commandes de vente peuvent être recherchées à l'aide du numéro de la commande de vente ou de la commande d'achat.",
  sites: "Sites",
  signin: "Se connecter",
  signUp: "S'inscrire",
  signUpDetails:
    "En créant un compte sur notre portail de service client, vous pourrez accéder à toutes les affaires liées à votre compte, créer de nouvelles affaires et suivre leur état d'avancement.",
  signIntoAccount: "Connectez-vous à votre compte",
  signInfailed: "Nom d'utilisateur ou mot de passe incorrect.",
  street1: "Rue 1 (obligatoire)",
  street2: "Rue 2",
  street3: "Rue 3",
  status: "Statut",
  surveys: "Enquêtes",
  submit: "Soumettre",
  suffix: "Suffixe (obligatoire)",
  suffixValidation: "* Le suffixe est requis",
  ticketNumber: "Numéro de case",
  type: "Type",
  update: "Mise à jour",
  updateCase: "Mise à jour du case",
  updatePassword: "Mise à jour du mot de passe",
  verifySuccess: "Vérification complétée.",
  verifyFailed: "Vérification échouée.",
  yes: "Oui",
};
