import { ApplicationState, initialUserState } from "../../types/Master";
import {
  action_types,
  API_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOADER,
  SET_NAVIGATION,
  UPDATE_USER,
  SET_TOAST_MESSAGE,
  SET_EDIT_CASE_NUMBER,
  SET_LANGUAGE,
} from "../../types/ActionTypes";
import { GetLoggedInUser } from "../../services/CustomerService";
import LocalizedStrings from "react-localization";
import localization from "../../localization";
import UseTranslation from "../../services/UseTranslation";

const user = GetLoggedInUser();
const initialState: ApplicationState = {
  isAuthUser: user ? true : false,
  user: user ? user : initialUserState,
  isLoading: false,
  selectedNavItem: "/home",
  error: null,
  toastMessage: "",
  isToastMessageSuccess: false,
  editCaseNumer: "",
  language: "gb",
  country: "uk",
  translation: UseTranslation(),
};

export default (
  state: ApplicationState = initialState,
  action: any
): ApplicationState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.user.Token));
      return { ...state, isAuthUser: true, user: action.payload.user };
    case UPDATE_USER:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, isAuthUser: true, user: action.payload };
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload.message,
        isToastMessageSuccess: action.payload.status,
      };
    case API_ERROR:
      return { ...state, error: action.payload };
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case SET_EDIT_CASE_NUMBER:
      return { ...state, editCaseNumer: action.payload };
    case SET_NAVIGATION:
      return { ...state, selectedNavItem: action.payload };
    case SET_LANGUAGE:
      let translation = new LocalizedStrings(localization);
      translation.setLanguage(action.payload.code);
      return {
        ...state,
        language: action.payload.code,
        country: action.payload.country,
        translation,
      };
    case LOGOUT:
      localStorage.removeItem("expiryTime");
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthUser: false,
        user: initialUserState,
      };
    default:
      return state;
  }
};
