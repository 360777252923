import { Backdrop, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { ApplicationState, article } from "../../types/Master";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { action_types } from "../../types/ActionTypes";
import { GetArticles } from "../../services/CustomerService";
import SearchBar from "material-ui-search-bar";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="tab-right-content"
    >
      {value === index && (
        <Box className="p-3">
          <Typography>{children} </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab--${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function renderHtml(val: string) {
  return <div dangerouslySetInnerHTML={{ __html: val }} />;
}

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Articles = (props: any) => {
  const { setNavigation, isLoading, setLoader } = props;
  const [data, setData] = React.useState<article[]>([]);
  const [value, setValue] = React.useState(0);
  const [searched, setSearched] = React.useState<string>("");
  const [originalRows, setOriginalRows] = React.useState<article[]>([]);
  const handleChange = (event: React.ChangeEvent<{}>, val: any) => {
    setValue(val);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalRows.filter((row) => {
      return row.Title.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setData(filteredRows);
  };

  React.useEffect(() => {
    setNavigation("/articles");
    setLoader(true);
    GetArticles()
      .then((res) => {
        setLoader(false);
        if (res) {
          setData(res);
          setOriginalRows(res);
        }
      })
      .catch((ex) => {
        setLoader(false);
        console.error(ex);
      });
  }, []);

  return (
    <Box className="search-article container">
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <Box className="loading-icon" />
      </Backdrop>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        className="searchbar-main p-2"
      />
      <Box className="d-flex-grow d-flex min-height-400">
        <Tabs
          value={value}
          orientation="vertical"
          variant="scrollable"
          aria-label="article tabs"
          onChange={handleChange}
          className="tab-left-content box-shadow-02"
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          {data.map((item, index) => {
            return <Tab label={item.Title} {...a11yProps(index)} />;
          })}
        </Tabs>
        {data.map((item, index) => {
          return (
            <TabPanel value={value} index={index}>
              {renderHtml(item.Content)}
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Articles);
