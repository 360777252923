export default {
  account: "Account",
  action: "Action",
  accountNumber: "Account Number",
  additionalDetails: "Enter Additional Details",
  addSalesLine: "Add Order Line",
  addAttachments: "Add Attachments",
  addressName: "Address Name (Required)",
  addressNameValidation: "* Address name is required",
  addressLine1Validation: "* Address Line1 is required",
  addressLine2Validation: "* Address Line2 is required",
  addressLine3Validation: "* Address Line3 is required",
  addressPostCodeValidation: "* Post code is required",
  businessPhone: "Business Phone",
  cancel: "Cancel",
  cases: "Cases",
  caseType: "Case Type (Required)",
  caseTypeValidation: "* Case type is required",
  caseCreationSuccess: "Successfuly created case",
  caseUpdationSuccess: "Successfuly updated case",
  caseCancellationSuccess: "Successfuly cancelled case",
  cancelCase: "Cancel Case",
  changePassword: "Change Password",
  contact: "Contact",
  country: "Country",
  collectionAddress: "Select Collection Address (Required)",
  collectionAddressNameValidation: "* Collection address name is required",
  collectionAddressValidation: "* Collection address is required",
  collectionAddressNotFound:
    "If Collection Address not found then select the toggle",
  completeSurvey: "Complete Survey",
  columns: "Columns",
  confirmPassword: "Confirm Password",
  conditionOfGoods: "Condition Of Goods (Required)",
  conditionOfGoodsValidation: "* Condition of goods is required",
  createAccount: "CREATE AN ACCOUNT",
  createdOn: "Created On",
  create: "Create",
  createCase: "Create New Case",
  createCaseInfo:
    "Please add required details and click on save to create a  new case.",
  currentPassword: "Current Password",
  description: "Description",
  descriptionValidation: "* Additional details is required",
  deliveryAddress: "Select Delivery Address (Required)",
  deliveryAddressNameValidation: "* Delivery address name is required",
  deliveryAddressValidation: "* Delivery address is required",
  deliveryAddressNotFound:
    "If Delivery Address not found then select the toggle",
  edit: "Edit",
  emailAddress: "Email Address",
  emailAlreadyExistMsg: "* Email already exists",
  emailValidationMsg: "Invalid Email Address",
  existingsignIn: "Existing User? Login in",
  faq: "FAQ",
  failed: "Failed",
  faultDetails: "Fault Details (Required)",
  faultValidation: "* Fault details is required",
  firstName: "First Name",
  forgetPassword: "Forgot Password",
  forgetPasswordLinkSuccess: "Activation link sent to your mail!",
  home: "Home",
  jobTitle: "Job Title",
  lastName: "Last Name",
  logOut: "Logout",
  myProfile: "My Account",
  myDetails: "My Details",
  mobilePhone: "Mobile Phone",
  mobileValidationMsg: "Invalid Mobile Number",
  newHere: "Look like you're new here!",
  newPassword: "New Password",
  newToPortal: "New to Portal?",
  noOrdersFound: "* No order lines found that are eligible for return",
  noOrderAdded:
    "* Sales order(lines) need to be added. Please select the Sales Order Line and then click on the green ‘Add Order Line’ button to complete this process",
  noSurvey: "No Survey",
  no: "No",
  orderNumber: "Order Number",
  partNumber: "Part Number Ordered",
  partNumberReceieved: "Part Number Received",
  password: "Password",
  passwordUpdateSuccess:
    "Your Password updated successfully, please sign in again with new password",
  passwordUpdateFail: "Password updation failed. try again after some time.",
  passwordMismatch: "Passwords did not match.",
  phone: "Phone",
  phoneValidationMsg: "Invalid Phone Number",
  pincode: "Post Code (Required)",
  profileUpdateSuccess: "Profile updated successfully",
  profileUpdateFail: "Error in updating profile",
  profileVerificationSuccess: "Verification email is sent sccessfully",
  profileVerificationFail: "Error in sending verification link",
  passwordPolicyMessage:
    "* Password must contain 8 characters with at least 1 Upper Case, 1 lower case,1 special character and 1 numeric character",
  product: "Product",
  quantity: "Quantity Ordered",
  quantityValidation: "Return qty should be less than the ordered qty",
  returnReason: "Return Reason (Required)",
  returnReasonValidation: "* Return Reason is required",
  receivedQty: "Quantity Received",
  returnQty: "Quantity to Return",
  reason: "Reason",
  resetPasswordfailed: "Reset password failed, Please check the entered E-Mail",
  replacementRequired: "Replacement Needed? (Required)",
  requestAnUpdate: "Request An Update",
  requestAnUpdateSubject: "Customer Request For Update",
  returnTerms: "Accepted Routeco Return Policy",
  rigisterFailed:
    "System error. Registration failed. Please contact Routeco Customer Service. customer.service@routeco.com",
  rigisterSuccess: "Registration successfully completed",
  seriesLetter: "Series Letter (Required)",
  seriesValidation: "* Series letter is required",
  salesOrder: "Sales Order",
  sendVerifyMail: "Send Verify Mail",
  selectOrderLine: "Select Product",
  selectOrderLineHelp:
    "Select each product and quantity that you would like to return and then click on the Add Order Line button.",
  selectedOrder: "Selected Order",
  search: "Search",
  searchOrder: "Enter Order Number (Required)",
  searchOrderHelpText:
    "Enter sales order or purchase order number.Sales orders can be searched using sales order number or purchase order number.",
  sites: "Sites",
  signin: "Sign In",
  signUp: "Sign Up",
  signUpDetails:
    "By creating an account on our Customer Service Portal, you will be able to access all the cases against your account, create new cases and track their status.",
  signIntoAccount: "Sign into account!",
  signInfailed: "Invalid username or password.",
  street1: "Street 1 (Required)",
  street2: "Street 2",
  street3: "Street 3",
  status: "Status",
  surveys: "Surveys",
  submit: "Submit",
  suffix: "Suffix (Required)",
  suffixValidation: "* Suffix is required",
  ticketNumber: "Case Number",
  type: "Type",
  update: "Update",
  updateCase: "Update Case",
  updatePassword: "Update Password",
  verifySuccess: "Verification completed.",
  verifyFailed: "Verification failed.",
  yes: "Yes",
};
