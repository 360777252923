import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Backdrop,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { CustomerSignIn } from "../../services/CustomerService";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState, User } from "../../types/Master";
import "../index.css";
import ToastMessage from "../shared/ToastMessage";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  toastMessage: state.toastMessage,
  isToastMessageSuccess: state.isToastMessageSuccess,
  translation: state.translation,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    loginSuccess: (val: { user: User; token: string; message: string }) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class SignIn extends React.Component<PropsFromRedux> {
  state: {
    checked: boolean;
    emailValidationMessage: string;
    pwdValidationMessage: string;
    isDisplayCenter: boolean;
  } = {
    checked: false,
    emailValidationMessage: "",
    pwdValidationMessage: "",
    isDisplayCenter: true,
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (this.validate(data.get("email"))) {
      this.props.setLoader(true);

      if (this.state.checked) {
        var current_time = new Date();
        current_time.setMinutes(current_time.getMinutes() + 30);
        localStorage.setItem("expiryTime", current_time + "");
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.setItem("rememberMe", "");
      }

      CustomerSignIn(data.get("email"), data.get("password"))
        .then((user) => {
          this.props.setLoader(false);
          if (user) this.props.loginSuccess(user);
          else {
            this.setState({ isDisplayCenter: false });
            this.props.setToastMessage(
              this.props.translation.signInfailed,
              false
            );
          }
        })
        .catch((ex) => {
          this.props.setLoader(false);
          this.setState({ isDisplayCenter: false });
          this.props.setToastMessage(
            this.props.translation.signInfailed,
            false
          );
        });
    }
  };

  validate = (email: any) => {
    let isValid = true;
    this.setState({
      emailValidationMessage: "",
    });
    const isEmailValid = /@/.test(email); // use any validator you want
    if (!isEmailValid) {
      this.setState({
        emailValidationMessage: this.props.translation.emailValidationMsg,
      });
      isValid = false;
    }
    return isValid;
  };
  handleRemember = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked });
  };

  handleClose = () => {
    this.props.setToastMessage("", false);
    this.setState({ isDisplayCenter: true });
  };

  render() {
    const { isLoading, translation } = this.props;

    return (
      <Box className="container">
        <Box className="d-flex error-message mt-4">
          <ToastMessage
            isOpen={this.props.toastMessage != ""}
            variant={
              this.props.isToastMessageSuccess
                ? "success"
                : this.props.toastMessage != ""
                ? "danger"
                : ""
            }
            message={this.props.toastMessage}
            handleToastClose={this.handleClose}
            autoHideDuration={60000}
            displayCenter={this.state.isDisplayCenter}
          />
        </Box>
        <Box className="d-flex sign-in-wrapper row d-flex-align-item-center mb-4 mt-2">
          <Box className="col-xs-12 col-sm-6 sign-in-left-wrapper">
            <Box className="table-styling">
              <h3 className="p-3">{translation.signIntoAccount}</h3>
              <Box
                component="form"
                onSubmit={this.handleSubmit}
                sx={{ mt: 1 }}
                className="p-3"
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={translation.emailAddress}
                  name="email"
                  autoComplete="email"
                  helperText={this.state.emailValidationMessage}
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                  autoFocus
                />
                <Box className="login-password">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={translation.password}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Box>
                <Box className="d-flex d-flex-align-item-center d-flex-justifyContent-space-between mb-3">
                  <Link
                    href="/forgotpassword"
                    variant="body2"
                    className="forgot-link"
                  >
                    {translation.forgetPassword}
                  </Link>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={this.props.isLoading}
                    className="ms-auto btn-button btn-blue p-2 px-3 "
                  >
                    {translation.signin}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="sign-in-right-wrapper col-xs-12 col-sm-6">
            <Box className="table-styling min-height-300">
              <h3 className="p-3">{translation.newToPortal}</h3>
              <Box className="p-3">
                <Typography>{translation.signUpDetails}</Typography>
                <Box className="d-flex-justifyContent-end d-flex">
                  <Link
                    href="/signup"
                    variant="body2"
                    className=" ms-auto btn-orange p-2 px-3 mt-2"
                  >
                    {translation.createAccount}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default connector(SignIn);
