import { Box, Backdrop } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { VerifyUser } from "../../services/CustomerService";
import CreatePassword from "./CreatePassword";
import { ApplicationState } from "../../types/Master";
import { action_types } from "../../types/ActionTypes";
import { connect } from "react-redux";
import ToastMessage from "../shared/ToastMessage";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  translation: state.translation,
});

const mapDispatchToProps = (dispatch: React.Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const UserVerification = (props: any) => {
  const { isLoading, setLoader, setToastMessage, translation } = props;
  const [isRedirect, setIsRedirect] = useState(false);
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    var contactid = new URLSearchParams(search).get("contactId");
    var pwdresetId = new URLSearchParams(search).get("pwdresetId");
    var reqesturl = window.location.href;
    if (contactid != "" && pwdresetId != "" && reqesturl != "") {
      pwdresetId = "3";
      setLoader(true);
      VerifyUser(contactid, pwdresetId, reqesturl, "")
        .then((res) => {
          setLoader(false);
          setIsVerificationCompleted(true);
          setShowCreatePassword(true);
        })
        .catch((ex) => {
          setLoader(false);
          setToastMessage(translation.verifyFailed, false);
          setIsRedirect(true);
        });
    }
  }, []);

  const handleClose = () => {
    setIsVerificationCompleted(false);
  };

  if (isRedirect) {
    return <Redirect to="/SignIn" />;
  }

  return (
    <Box className="d-flex left-side-wrapper row my-4">
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <Box className="loading-icon" />
      </Backdrop>
      <ToastMessage
        isOpen={isVerificationCompleted}
        variant="success"
        message={translation.verifySuccess}
        handleToastClose={handleClose}
      />
      {showCreatePassword && <CreatePassword />}
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification);
