import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import "../index.css";
import { ApplicationState } from "../../types/Master";
import { Breadcrumbs, Link } from "@material-ui/core";
import { Home, NavigateNext } from "@material-ui/icons";

const Layout = (props: any) => {
  const { selectedNavItem, isAuthUser, translation } = props;
  return (
    <React.Fragment>
      <NavMenu />
      <Container>
        {selectedNavItem != "/home" && isAuthUser && (
          <Breadcrumbs
            className="my-2"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" key="1" color="inherit" href="/">
              <Home />
              {translation.home}
            </Link>
            <Link
              underline="hover"
              key="2"
              color="inherit"
              href={selectedNavItem}
            >
              {selectedNavItem.substring(1).charAt(0).toUpperCase() +
                selectedNavItem.substring(1).slice(1)}
            </Link>
          </Breadcrumbs>
        )}
        {props.children}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthUser: state.isAuthUser,
  isLoading: state.isLoading,
  selectedNavItem: state.selectedNavItem,
  translation: state.translation,
});

export default connect(mapStateToProps)(Layout);
