import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Layout from "./components/navigation/Layout";
import Home from "./components/navigation/Home";
import "./custom.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Profile from "./components/customer/Profile";
import { Provider } from "react-redux";
import { createStore } from "redux";
import SignIn from "./components/customer/SignIn";
import SignUp from "./components/customer/SignUp";
import AuthRoute from "./AuthRoute";
import reducers from "./store/reducers";
import Verification from "./components/customer/Verification";
import ForgotPassword from "./components/customer/ForgotPassword";
import UserVerification from "./components/customer/UserVerification";
import Cases from "./components/customer/Cases";
import Articles from "./components/customer/Articles";
import Footer from "./components/navigation/Footer";
import Surveys from "./components/customer/Surveys";
import { Box } from "@material-ui/core";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#000000",
    },
  },
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "isLoading",
    "editCaseNumer",
    "toastMessage",
    "isToastMessageSuccess",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);
class App extends React.Component {
  state: {
    isAuthenticated: boolean;
  } = { isAuthenticated: false };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <Layout />
              <Box className="fullWidth">
                <Switch>
                  <AuthRoute path="/login" type="guest">
                    <SignIn />
                  </AuthRoute>
                  <AuthRoute path="/signup" type="guest">
                    <SignUp />
                  </AuthRoute>
                  <AuthRoute path="/ForgotPassword" type="guest">
                    <ForgotPassword />
                  </AuthRoute>
                  <AuthRoute path="/verify" type="guest">
                    <Verification />
                  </AuthRoute>
                  <AuthRoute path="/verifyuser" type="guest">
                    <UserVerification />
                  </AuthRoute>
                  <AuthRoute path="/home" type="private">
                    <Home />
                  </AuthRoute>
                  <AuthRoute path="/profile" type="private">
                    <Profile />
                  </AuthRoute>
                  <AuthRoute path="/cases" type="private">
                    <Cases />
                  </AuthRoute>
                  <AuthRoute path="/articles" type="private">
                    <Articles />
                  </AuthRoute>
                  <AuthRoute path="/surveys" type="private">
                    <Surveys />
                  </AuthRoute>
                  <Redirect from="*" to="/login" />
                </Switch>
              </Box>
            </Router>
            <Footer />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
