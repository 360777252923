import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  ApplicationState,
  OpCoCountry,
  Registration,
} from "../../types/Master";
import * as React from "react";
import "../index.css";
import { CustomerSignUp, GetCountries } from "../../services/CustomerService";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { action_types } from "../../types/ActionTypes";
import { Redirect } from "react-router-dom";
import ToastMessage from "../shared/ToastMessage";
import validator from "validator";
import { getCaseType } from "../../services/Shared";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  translation: state.translation,
  language: state.language,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class SignUp extends React.Component<PropsFromRedux> {
  state: {
    isRegistrationSucceeded: boolean;
    registrationfailedMessage: string;
    emailValidationMessage: string;
    passwordValidationMessage: string;
    countries: OpCoCountry[];
  } = {
    isRegistrationSucceeded: false,
    registrationfailedMessage: "",
    emailValidationMessage: "",
    passwordValidationMessage: "",
    countries: [],
  };

  reg: Registration = {
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    TelComercial: "",
    TelCelular: "",
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("selectedLanguage", this.props.language);
    // eslint-disable-next-line no-console
    if (
      this.validate(
        data.getAll("email")[0].toString(),
        data.getAll("password")[0].toString(),
        data.getAll("confirmpassword")[0].toString()
      )
    ) {
      this.props.setLoader(true);
      CustomerSignUp(data)
        .then((response) => {
          this.props.setLoader(false);
          if (response.status == "success") {
            this.props.setToastMessage(response.message, false);
            this.setState({ isRegistrationSucceeded: true });
          }
        })
        .catch((ex) => {
          this.props.setLoader(false);
          this.setState({
            registrationfailedMessage: this.props.translation.rigisterFailed,
          });
        });
    }
  };

  validate = (
    email: string,
    password: string,
    confirmPassword: string
  ): boolean => {
    let isValid: boolean = true;
    if (!validator.isEmail(email)) {
      isValid = false;
      this.setState({
        emailValidationMessage: this.props.translation.emailValidationMessage,
      });
    } else {
      this.setState({
        emailValidationMessage: "",
      });
    }
    if (password != confirmPassword) {
      isValid = false;
      this.setState({
        passwordValidationMessage: this.props.translation.passwordMismatch,
      });
      return isValid;
    } else {
      this.setState({
        passwordValidationMessage: "",
      });
    }
    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      isValid = false;
      this.setState({
        passwordValidationMessage: this.props.translation.passwordPolicyMessage,
      });
      return isValid;
    } else {
      this.setState({
        passwordValidationMessage: "",
      });
    }
    return isValid;
  };

  handleClose = () => {
    this.setState({
      registrationfailedMessage: "",
    });
  };

  componentDidMount() {
    this.props.setLoader(true);
    GetCountries()
      .then((res) => {
        this.props.setLoader(false);
        if (res) this.setState({ countries: res });
      })
      .catch((ex) => {
        this.props.setLoader(false);
        console.error(ex);
      });
  }

  render() {
    const { isRegistrationSucceeded } = this.state;
    const { isLoading, translation } = this.props;

    if (isRegistrationSucceeded) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Box className="container">
        <Box className="d-flex left-side-wrapper row my-4">
          <ToastMessage
            isOpen={this.state.registrationfailedMessage != ""}
            variant="danger"
            message={this.state.registrationfailedMessage}
            handleToastClose={this.handleClose}
            autoHideDuration={60000}
            displayCenter={true}
          />
          <Backdrop
            style={{
              zIndex: 2250,
            }}
            open={isLoading}
          >
            <Box className="loading-icon" />
          </Backdrop>
          <Box className="col-xs-12 col-sm-6 left-side-main">
            <Box className="color-blue-background left-side-img">
              <h2 className="color-white p-4">{translation.newHere}</h2>
              <Box className="signup-left-image">
                <img src={require("../../images/sign-in-image.png")} />
              </Box>
            </Box>
          </Box>
          <Box className="right-side-text d-flex-justifyContent-end col-xs-12 col-sm-6">
            <Box component="form" onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="FirstName"
                    label={translation.firstName}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="LastName"
                    label={translation.lastName}
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="Email"
                    label={translation.emailAddress}
                    name="email"
                    autoComplete="email"
                    helperText={this.state.emailValidationMessage}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="select-label">
                      {`${translation.country || "Country"} *`}
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-country"
                      name="selectedCountry"
                      label={translation.country || "Country"}
                      fullWidth
                      className="dropdown"
                      style={{ minWidth: 120 }}
                      required
                    >
                      {this.state.countries &&
                        this.state.countries.map((item) => {
                          return (
                            <MenuItem value={item.Id}>{item.Name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="Account"
                    label={translation.accountNumber}
                    name="accountNumber"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label={translation.password}
                    type="password"
                    id="Password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmpassword"
                    label={translation.confirmPassword}
                    type="password"
                    id="confirmpassword"
                    autoComplete="confirm-password"
                    helperText={this.state.passwordValidationMessage}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="d-flex d-flex-justifyContent-space-between mt-2 ">
                <Link
                  href="/signin"
                  variant="body2"
                  className="ms-auto p-2 px-3 btn-orange-border"
                >
                  {translation.existingsignIn}
                </Link>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={this.props.isLoading}
                  className="ms-auto btn-button btn-blue p-2 px-3"
                >
                  {translation.signUp}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default connector(SignUp);
