import { Box, Snackbar } from "@material-ui/core";
import { CheckOutlined, WarningOutlined } from "@material-ui/icons";
import * as React from "react";
import { Alert } from "reactstrap";

export interface ToastProps {
  isOpen: boolean;
  message: string;
  variant: string;
  autoHideDuration?: number;
  displayCenter?: boolean;
  handleToastClose: () => void;
}

const ToastMessage = (props: ToastProps) => {
  const {
    isOpen,
    message,
    variant,
    displayCenter,
    autoHideDuration,
    handleToastClose,
  } = props;

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration || 10000}
      onClose={handleToastClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className={displayCenter ? "displayCenter" : ""}
    >
      <Box className="snackbar">
        <Alert color={variant} toggle={handleToastClose}>
          {variant == "danger" ? <WarningOutlined /> : <CheckOutlined />}
          {message}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default ToastMessage;
