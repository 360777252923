export default {
  CASE_TYPES: [
    { Code: "1", Value: "Complaint" },
    { Code: "2", Value: "Customer Return" },
    { Code: "3", Value: "NPSR" },
    { Code: "4", Value: "Pick Error" },
  ],
  DATE_TYPE: "en-GB",
  VERIFY_URL: "https://d365customerportal-prod.azurewebsites.net/verify",
  RETURN_POLICY_URL:
    "https://www.routeco.com/en-gb/contact-us/returns#full-policy",
  RETURN_POLICY_URL_GB:
    "https://www.routeco.com/en-gb/contact-us/returns#full-policy",
  RETURN_POLICY_URL_BE_DU:
    "https://www.routeco.com/nl-be/contacteer-ons/retourzendingen#full-policy",
  RETURN_POLICY_URL_BE_FR:
    "https://www.routeco.com/fr-be/contactez-nous/retourzendingen#full-policy",
  RETURN_POLICY_URL_AT:
    "https://www.routeco.com/de-at/kontakt/rucksendungen#full-policy",
  RETURN_POLICY_URL_NL:
    "https://www.routeco.com/nl-nl/neem-contact-met-ons-op/retourzendingen#full-policy",
};
