import { Box, Typography, Grid } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import "../index.css";
import { Dispatch } from "redux";

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
  };
};

const Home = (props: any) => {
  const { setNavigation } = props;

  useEffect(() => {
    setNavigation("/home");
  }, []);

  return (
    <Box>
      <Box className="homepage-banner"></Box>
    </Box>
  );
};

export default connect(null, mapDispatchToProps)(Home);
