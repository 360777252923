import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  ListItemText,
  List,
  ListItem,
  Switch,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Checkbox,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  CreateNewCase,
  GetAddresses,
  GetCaseDetails,
  GetCaseSubTypes,
  GetCaseTypes,
  GetConditionOfGoodsOptions,
  GetSearchOrderDetails,
} from "../../services/CustomerService";
import {
  CaseRequestTypes,
  getCaseType,
  getConditionOfGoods,
  getfileType,
  getReturnPolicyUrl,
  getSubCaseType,
} from "../../services/Shared";
import {
  Address,
  ApplicationState,
  Case,
  CaseSubType,
  CaseType,
  ConditionOfGoods,
  CustomerAddress,
  defaultAddress,
  defaultCustomerAddress,
  defaultErrorValidation,
  defaultOrderLine,
  errorValidation,
  initialCaseState,
  OrderLine,
} from "../../types/Master";
import { action_types } from "../../types/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { HelpOutlined, Search } from "@material-ui/icons";
import CreateCaseOrderLineSelection from "./CreateCaseOrderLineSelection";
import Defines from "../../config";

const mapStateToProps = (state: ApplicationState) => ({
  editCaseNumer: state.editCaseNumer,
  user: state.user,
  translation: state.translation,
  language: state.language,
  country: state.country,
});

const mapDispatchToProps = (dispatch: React.Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
    setEditCaseNumber: (val: string) =>
      dispatch({ type: "SET_EDIT_CASE_NUMBER", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  caseTypes: CaseType[];
  getCases: (
    isNewCaseCreated: boolean,
    isCaseUpdated: boolean,
    translation: any
  ) => void;
  setIsCaseUpdationfailed: (val: boolean) => void;
  setCaseUpdationMessage: (val: string) => void;
}

export type CreateCaseProps = PropsFromRedux & OwnProps;

const CreateCase = (props: CreateCaseProps) => {
  const {
    editCaseNumer,
    user,
    translation,
    language,
    country,
    caseTypes,
    setLoader,
    getCases,
    setIsCaseUpdationfailed,
    setCaseUpdationMessage,
    setEditCaseNumber,
  } = props;
  const [isCreateCaseOpen, setCreateCaseOpen] = useState(false);
  const [files, setFiles] = useState<FileList>();
  const [searched, setSearched] = useState<string>("");
  const [selectedCaseType, setSelectedCaseType] = useState<string>("");
  const [selectedCaseTypeName, setSelectedCaseTypeName] = useState<string>("");
  const [caseSubTypes, setCaseSubTypes] = useState<CaseSubType[]>([]);
  const [conditionOfGoodsOptions, setConditionOfGoodsOptions] = useState<
    ConditionOfGoods[]
  >([]);
  const [selectedConditionOfGoods, setSelectedConditionOfGoods] =
    useState<string>("");
  const [returnReasons, setReturnReasons] = useState<CaseSubType[]>([]);
  const [selectedReturnReason, setSelectedReturnReason] = useState<string>("");
  const [addresses, setAddresses] = useState<CustomerAddress[]>([]);
  const [selectedCollectionAddress, setSelectedCollectionAddress] =
    useState<CustomerAddress>(defaultCustomerAddress);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<CustomerAddress>(defaultCustomerAddress);
  const [isCollectionAddressNotFound, setIsCollectionAddressNotFound] =
    useState<boolean>(false);
  const [isDeliveryAddressNotFound, setIsDeliveryAddressNotFound] =
    useState<boolean>(false);
  const [isReplacementRequired, setIsReplacementRequired] =
    useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<OrderLine[]>();
  const [errors, setErrors] = useState<errorValidation>({
    ...defaultErrorValidation,
  });
  const [selectedOrderLines, setSelectedOrderLines] = useState<OrderLine[]>([]);
  const [isAcceptReturnPolicy, setIsAcceptReturnPolicy] =
    useState<boolean>(false);
  const [isEditCase, setIsEditCase] = useState<boolean>(false);
  const [caseDetails, setCaseDetails] = React.useState<Case>(initialCaseState);
  const [collectionAddress, setCollectionAddress] =
    React.useState<Address>(defaultAddress);
  const [deliveryAddress, setDeliveryAddress] =
    React.useState<Address>(defaultAddress);
  const [faultDetails, setfaultDetails] = React.useState<string>("");
  const [serialNumber, setSerialNumber] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [suffix, setSuffix] = React.useState<string>("");
  const [searchOrderTooltipText, setSearchOrderTooltipText] =
    React.useState<string>(translation.searchOrderHelpText);
  const [isNoOrderLinesToAdd, setIsNoOrderLinesToAdd] =
    React.useState<boolean>(false);
  const [disableOrderSearch, setDisableOrderSearch] =
    React.useState<boolean>(false);
  const [selectedOrderLine, setSelectedOrderLine] = useState<OrderLine>({
    ...defaultOrderLine,
  });

  useEffect(() => {
    if (editCaseNumer != "") setCreateCaseOpen(true);
  }, [editCaseNumer]);

  useEffect(() => {
    if (editCaseNumer != "") {
      setLoader(true);
      GetCaseDetails(user.AccountId, editCaseNumer)
        .then((res) => {
          setLoader(false);
          if (res) setCaseDetails(res);
        })
        .catch((ex) => {
          setLoader(false);
          console.error(ex);
        });
    }
  }, [editCaseNumer]);

  useEffect(() => {
    if (caseDetails.ID != "") {
      setIsEditCase(true);
      setSelectedCaseType(caseDetails.TypeId);
      setSelectedConditionOfGoods(caseDetails.ConditionOfGoods);
      setIsCollectionAddressNotFound(caseDetails.IsCollectionAddressNotFound);
      setIsDeliveryAddressNotFound(caseDetails.IsDeliveryAddressNotFound);
      setIsReplacementRequired(caseDetails.IsReplecementRequired);
      setCollectionAddress(caseDetails.CollectionAddress);
      setDeliveryAddress(caseDetails.DeliveryAddress);
      setfaultDetails(caseDetails.FaultDetails);
      setSerialNumber(caseDetails.SerialNumber);
      setDescription(caseDetails.Description);
      setSuffix(caseDetails.Suffix);
      setIsAcceptReturnPolicy(caseDetails.AcceptReturnPolicy);
      if (caseDetails.OrderNumber != "") {
        setDisableOrderSearch(true);
        searchOrder(
          caseDetails.OrderNumber,
          typeNameById(caseDetails.TypeId),
          false
        )
          .then((searchOrderDetails) => {
            let tmpOrderDetails: OrderLine[] = [];
            caseDetails.SalesOrderLines.forEach((item) => {
              let partNumber = "";
              let orderQty = "";
              searchOrderDetails.every((orderItem) => {
                if (orderItem.OrderDetailId == item.OrderDetailId) {
                  partNumber = orderItem.ProductNumber;
                  orderQty = orderItem.Quantity;
                  return false;
                } else return true;
              });
              let detailItem: OrderLine = { ...defaultOrderLine };
              detailItem.OrderNumber = caseDetails.OrderNumber;
              detailItem.isExistingReturnItem = true;
              detailItem.OrderDetailId = item.OrderDetailId;
              detailItem.Quantity = orderQty;
              detailItem.ProductID = item.ProductName;
              detailItem.ToBeReturnedQty = item.ProductQuantity;
              detailItem.ProductNumberReceived = item.ProductNumberReceived;
              detailItem.ProductNumber = partNumber;
              tmpOrderDetails.push(detailItem);
            });
            if (searchOrderDetails.length == 0) {
              setIsNoOrderLinesToAdd(true);
              setOrderDetails(tmpOrderDetails);
            }
            setSelectedOrderLines(tmpOrderDetails);
            setErrors({ ...defaultErrorValidation });
          })
          .catch((ex) => {
            console.error(ex);
          });
      } else {
        setOrderDetails(undefined);
        setSelectedOrderLines([]);
        setDisableOrderSearch(false);
        setErrors({ ...defaultErrorValidation });
      }
      handlecaseTypeSelectionChange(caseDetails.TypeId);
      setSelectedReturnReason(caseDetails.SubTypeId);
    }
  }, [caseDetails]);

  const handleCaseCreationClose = () => {
    setCreateCaseOpen(false);
    setEditCaseNumber("");
  };

  const handleCreate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditCaseNumber("");
    setCaseDetails(initialCaseState);
    setIsEditCase(false);
    setCreateCaseOpen(true);
    setFormFieldsToDefault();
  };

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.files && setFiles(event.target.files);
  };

  const setFormFieldsToDefault = () => {
    setDisableOrderSearch(false);
    setSearched("");
    setSelectedCaseType("");
    setSelectedConditionOfGoods("");
    setSelectedReturnReason("");
    setSelectedOrderLines([]);
    setSelectedCaseTypeName("");
    setSelectedCollectionAddress({ ...defaultCustomerAddress });
    setCollectionAddress({ ...defaultAddress });
    setIsCollectionAddressNotFound(false);
    setSelectedDeliveryAddress({ ...defaultCustomerAddress });
    setDeliveryAddress({ ...defaultAddress });
    setIsDeliveryAddressNotFound(false);
    setOrderDetails(undefined);
    setIsReplacementRequired(false);
    setErrors({ ...defaultErrorValidation });
    setIsAcceptReturnPolicy(false);
    setFiles(undefined);
    setSerialNumber("");
    setfaultDetails("");
    setDescription("");
    setSuffix("");
  };

  const validateOrder = (data: FormData): boolean => {
    let isValidate = false;
    if (orderDetails && orderDetails.length > 0) {
      data.append("salesOrderId", orderDetails[0].OrderId);
      if (
        selectedCaseTypeName.toLowerCase() == CaseRequestTypes.RETURN ||
        selectedCaseTypeName.toLowerCase() == CaseRequestTypes.PICK_ERROR ||
        selectedCaseTypeName.toLowerCase() == CaseRequestTypes.NPSR
      ) {
        if (selectedOrderLines && selectedOrderLines.length > 0) {
          let lineIds: {
            salesOrderLineId: string;
            quantity: number;
            productNumberReceived: string;
          }[] = [];
          selectedOrderLines.forEach((item) => {
            let qty = parseInt(item.ToBeReturnedQty);
            lineIds.push({
              salesOrderLineId: item.OrderDetailId,
              quantity: qty,
              productNumberReceived: item.ProductNumberReceived,
            });
          });
          if (lineIds.length == selectedOrderLines.length) {
            data.append("salesOrderLineItemsJson", JSON.stringify(lineIds));
            isValidate = true;
          }
        } else {
          isValidate = false;
        }
      } else {
        isValidate = true;
      }
    } else {
      isValidate = false;
    }
    return isValidate;
  };

  const validateFields = (data: FormData) => {
    let temperrors = { ...defaultErrorValidation };
    let isValidate = true;
    if (selectedCaseType == "") {
      temperrors = { ...temperrors, caseType: translation.caseTypeValidation };
      isValidate = false;
    }

    if (
      selectedCaseTypeName.toLowerCase() != "" &&
      selectedCaseTypeName.toLowerCase() == CaseRequestTypes.PICK_ERROR &&
      suffix == ""
    ) {
      temperrors = {
        ...temperrors,
        suffix: translation.suffixValidation,
      };
      isValidate = false;
    }

    if (!validateOrder(data)) {
      temperrors = { ...temperrors, searchOrderLine: translation.noOrderAdded };
      isValidate = false;
    }
    if (
      selectedCaseTypeName.toLowerCase() != "" &&
      selectedCaseTypeName.toLowerCase() != CaseRequestTypes.COMPLAINT &&
      selectedReturnReason == ""
    ) {
      temperrors = {
        ...temperrors,
        caseSubType: translation.returnReasonValidation,
      };
      isValidate = false;
    }
    if (
      selectedCaseTypeName.toLowerCase() != "" &&
      selectedCaseTypeName.toLowerCase() != CaseRequestTypes.COMPLAINT &&
      !isCollectionAddressNotFound &&
      selectedCollectionAddress.Id == ""
    ) {
      temperrors = {
        ...temperrors,
        collectionAddress: translation.collectionAddressValidation,
      };
      isValidate = false;
    }
    if (isCollectionAddressNotFound) {
      if (collectionAddress.AddressName == "") {
        temperrors = {
          ...temperrors,
          collectionAddressName: translation.collectionAddressNameValidation,
        };
        isValidate = false;
      }
      if (collectionAddress.AddressLine1 == "") {
        temperrors = {
          ...temperrors,
          collectionAddressStreet1: translation.addressLine1Validation,
        };
        isValidate = false;
      }
      if (collectionAddress.AddressPostalCode == "") {
        temperrors = {
          ...temperrors,
          collectionAddressPostCode: translation.addressPostCodeValidation,
        };
        isValidate = false;
      }
    }

    if (
      (selectedCaseTypeName.toLowerCase() == CaseRequestTypes.RETURN ||
        selectedCaseTypeName.toLowerCase() == CaseRequestTypes.PICK_ERROR ||
        selectedCaseTypeName.toLowerCase() == CaseRequestTypes.NPSR) &&
      !isDeliveryAddressNotFound &&
      selectedDeliveryAddress.Id == ""
    ) {
      temperrors = {
        ...temperrors,
        deliveryAddress: translation.deliveryAddressValidation,
      };
      isValidate = false;
    }

    if (isDeliveryAddressNotFound) {
      if (deliveryAddress.AddressName == "") {
        temperrors = {
          ...temperrors,
          deliveryAddressName: translation.deliveryAddressNameValidation,
        };
        isValidate = false;
      }
      if (deliveryAddress.AddressLine1 == "") {
        temperrors = {
          ...temperrors,
          deliveryAddressStreet1: translation.addressLine1Validation,
        };
        isValidate = false;
      }
      if (deliveryAddress.AddressPostalCode == "") {
        temperrors = {
          ...temperrors,
          deliveryAddressPostCode: translation.addressPostCodeValidation,
        };
        isValidate = false;
      }
    }

    if (
      selectedCaseTypeName.toLowerCase() != "" &&
      selectedCaseTypeName.toLowerCase() == CaseRequestTypes.NPSR
    ) {
      if (faultDetails == "") {
        temperrors = {
          ...temperrors,
          faultDetails: translation.faultValidation,
        };
        isValidate = false;
      }

      if (serialNumber == "") {
        temperrors = {
          ...temperrors,
          serialNumber: translation.seriesValidation,
        };
        isValidate = false;
      }
    }

    if (
      selectedCaseTypeName.toLowerCase() != "" &&
      selectedCaseTypeName.toLowerCase() == CaseRequestTypes.RETURN &&
      selectedConditionOfGoods == ""
    ) {
      temperrors = {
        ...temperrors,
        conditionOfGoods: translation.conditionOfGoodsValidation,
      };
      isValidate = false;
    }

    if (!isValidate) setErrors(temperrors);
    return isValidate;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (validateFields(data)) {
      data.append("customerid", user.ID);
      data.append("accountId", user.AccountId);
      data.append("incidentId", caseDetails.ID);
      data.append(
        "isCollectionAddressNotFound",
        JSON.stringify(isCollectionAddressNotFound)
      );
      data.append(
        "isDeliveryAddressNotFound",
        JSON.stringify(isDeliveryAddressNotFound)
      );
      data.append(
        "isReplacementRequired",
        JSON.stringify(isReplacementRequired)
      );
      data.append("accountId", user.AccountId);
      data.append("typeName", selectedCaseTypeName);
      setLoader(true);
      if (files && files.length > 0) {
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension ? getfileType(extension) : "";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("fileType", fileType);
      }
      CreateNewCase(data)
        .then((res) => {
          setFormFieldsToDefault();
          handleCaseCreationClose();
          setLoader(false);
          getCases(isEditCase ? false : true, isEditCase, translation);
        })
        .catch((ex) => {
          setLoader(false);
          setIsCaseUpdationfailed(true);
          setCaseUpdationMessage(translation.failed);
          setFormFieldsToDefault();
          handleCaseCreationClose();
          console.error(ex);
        });
    }
  };

  useEffect(() => {
    GetCaseSubTypes()
      .then((res) => {
        if (res) setCaseSubTypes(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
    GetAddresses(user.AccountId)
      .then((res) => {
        if (res) setAddresses(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
    GetConditionOfGoodsOptions()
      .then((res) => {
        if (res) setConditionOfGoodsOptions(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const requestSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    searchOrder(searched, selectedCaseTypeName, true);
  };

  const searchOrder = (
    searchText: string,
    type: string,
    isNewCase: boolean
  ): Promise<OrderLine[]> => {
    let orderLines: OrderLine[] = [];
    return new Promise((resolve, reject) => {
      if (type != "") {
        if (searchText != "") {
          setLoader(true);
          GetSearchOrderDetails(user.AccountId, searchText, type)
            .then((res) => {
              setSelectedOrderLine({ ...defaultOrderLine });
              orderLines = validateOrderLineQtyForReturn(res, type, isNewCase);
              setLoader(false);
              resolve(orderLines);
            })
            .catch((ex) => {
              setLoader(false);
              setSelectedOrderLine({ ...defaultOrderLine });
              setErrors({ ...errors, searchOrder: translation.noOrdersFound });
              setOrderDetails(undefined);
              console.error(ex);
              reject();
            });
        } else {
          setErrors({ ...errors, searchOrder: translation.noOrdersFound });
          reject();
        }
      } else {
        setErrors({ ...errors, caseType: translation.caseTypeValidation });
        reject();
      }
    });
  };

  const validateOrderLineQtyForReturn = (
    orderLines: OrderLine[] | undefined,
    type: string,
    isNewCase: boolean
  ) => {
    let tmpOrderLines: OrderLine[] = [];
    if (orderLines) {
      orderLines.forEach((item) => {
        if (isNewCase) {
          if (parseInt(item.ReturnQty) == 0) {
            tmpOrderLines.push(item);
          }
        } else tmpOrderLines.push(item);
      });
    }
    setSelectedOrderLines([]);
    if (tmpOrderLines.length > 0) {
      setErrors({ ...errors, searchOrder: "", searchOrderLine: "" });
      setOrderDetails(tmpOrderLines);
    } else {
      setErrors({ ...errors, searchOrder: translation.noOrdersFound });
      setOrderDetails(undefined);
    }
    return tmpOrderLines;
  };

  useEffect(() => {
    typeNameById(selectedCaseType);
    setSearched("");
    !isEditCase && setOrderDetails(undefined);
    setErrors({ ...errors, caseType: "" });
    setSelectedCollectionAddress({ ...defaultCustomerAddress });
    !isEditCase && setIsCollectionAddressNotFound(false);
    setSelectedDeliveryAddress({ ...defaultCustomerAddress });
    !isEditCase && setIsDeliveryAddressNotFound(false);
  }, [selectedCaseType]);

  const typeNameById = (typeId: string) => {
    let typeName = "";
    caseTypes.every((item) => {
      if (item.Id == typeId) {
        typeName = item.Name;
        if (item.Name.toLowerCase() == CaseRequestTypes.COMPLAINT)
          setSearchOrderTooltipText(translation.searchOrderHelpText);
        if (item.Name.toLowerCase() == CaseRequestTypes.RETURN)
          setSearchOrderTooltipText(translation.searchOrderHelpText);
        if (item.Name.toLowerCase() == CaseRequestTypes.NPSR)
          setSearchOrderTooltipText(translation.searchOrderHelpText);
        if (item.Name.toLowerCase() == CaseRequestTypes.PICK_ERROR)
          setSearchOrderTooltipText(translation.searchOrderHelpText);
        return false;
      } else return true;
    });
    setSelectedCaseTypeName(typeName);
    return typeName;
  };

  const handlecaseTypeSelectionChange = (typeId: string) => {
    let returnReasons: CaseSubType[] = [];
    caseSubTypes.forEach((item) => {
      if (item.TypeId == typeId) {
        returnReasons.push(item);
      }
    });
    setSelectedCaseType(typeId);
    setReturnReasons(returnReasons);
  };

  const handleSelectedAddress = (
    val: string,
    isCollectionAddress: boolean
  ): void => {
    addresses.every((item) => {
      if (item.Id == val) {
        if (isCollectionAddress) setSelectedCollectionAddress(item);
        else setSelectedDeliveryAddress(item);
        return false;
      } else return true;
    });
  };

  useEffect(() => {
    if (returnReasons && returnReasons.length == 1) {
      setSelectedReturnReason(returnReasons[0].Id);
    }
    if (caseDetails.ID != "") {
      handleSelectedAddress(caseDetails.CollectionAddressSite, true);
      handleSelectedAddress(caseDetails.DeliveryAddressSite, false);
    }
  }, [selectedOrderLines]);

  useEffect(() => {
    if (isCollectionAddressNotFound)
      setSelectedCollectionAddress({ ...defaultCustomerAddress });
  }, [isCollectionAddressNotFound]);

  useEffect(() => {
    if (isDeliveryAddressNotFound)
      setSelectedDeliveryAddress({ ...defaultCustomerAddress });
  }, [isDeliveryAddressNotFound]);

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="contained"
        className="ms-auto btn-button btn-blue p-2 px-3 mb-3 btn-cases"
        onClick={(e) => handleCreate(e)}
      >
        {translation.createCase}
      </Button>
      <Box className="d-flex d-flex-justifyContent-end">
        <Dialog
          PaperProps={{
            style: {
              minWidth: "55%",
            },
          }}
          open={isCreateCaseOpen}
          onClose={handleCaseCreationClose}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <DialogTitle>
              {isEditCase ? translation.updateCase : translation.createCase}
            </DialogTitle>
            <DialogContent className="p-4">
              <DialogContentText className="cases-title">
                {translation.createCaseInfo}
              </DialogContentText>
              <Box className="my-2">
                {caseDetails && caseDetails.TicketNumber != "" && (
                  <React.Fragment>
                    <Typography className="mb-1 dark-grey-01">
                      {translation.ticketNumber}
                    </Typography>
                    <Typography className="light-grey-color-02">
                      {caseDetails.TicketNumber}
                    </Typography>
                    <Typography className="mb-1 dark-grey-01">
                      {translation.salesOrder}
                    </Typography>
                    <Typography className="light-grey-color-02">
                      {caseDetails.OrderNumber}
                    </Typography>
                  </React.Fragment>
                )}
                <FormControl className="my-2" fullWidth>
                  <InputLabel htmlFor="select-case-type">
                    {translation.caseType}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="select-case-type"
                    name="TypeId"
                    value={selectedCaseType}
                    label={translation.type}
                    fullWidth
                    className="dropdown"
                    onChange={(e) =>
                      handlecaseTypeSelectionChange(e.target.value as string)
                    }
                    style={{ minWidth: 120 }}
                    disabled={isEditCase}
                  >
                    {caseTypes &&
                      caseTypes.map((item) => {
                        return (
                          <MenuItem value={item.Id}>
                            {getCaseType(item, language)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText
                    error
                    id="search-error"
                    className="helper-text"
                  >
                    {selectedCaseType == "" && errors && errors.caseType}
                  </FormHelperText>
                </FormControl>
                {selectedCaseTypeName.toLowerCase() ==
                  CaseRequestTypes.PICK_ERROR && (
                  <TextField
                    id="suffix"
                    label={translation.suffix}
                    value={suffix}
                    onChange={(e) => setSuffix(e.target.value as string)}
                    name="suffix"
                    className="my-2"
                    fullWidth
                    inputProps={{ maxLength: 2000 }}
                    helperText={errors.suffix}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                  />
                )}
                {caseDetails && caseDetails.TicketNumber == "" && (
                  <FormControl className="mt-4 search-createcase" fullWidth>
                    <InputLabel htmlFor="search-input">
                      {translation.searchOrder}
                    </InputLabel>
                    <OutlinedInput
                      id="search-input"
                      value={searched}
                      label={translation.searchOrder}
                      style={{ width: "100%" }}
                      onChange={(e) => setSearched(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={translation.search}
                            onClick={(e) => requestSearch(e)}
                            disabled={disableOrderSearch}
                          >
                            <Search />
                          </IconButton>
                          <Tooltip
                            title={
                              <Typography
                                className="tooltip-text"
                                style={{ color: "white" }}
                              >
                                {searchOrderTooltipText}
                              </Typography>
                            }
                          >
                            <HelpOutlined className="tooltip-help" />
                          </Tooltip>
                        </InputAdornment>
                      }
                      disabled={disableOrderSearch}
                    />
                    <FormHelperText
                      error
                      id="search-error"
                      className="helper-text"
                    >
                      {errors.searchOrder}
                    </FormHelperText>
                  </FormControl>
                )}
                <React.Fragment>
                  {orderDetails && orderDetails.length > 0 && (
                    <React.Fragment>
                      {selectedCaseTypeName.toLowerCase() ==
                      CaseRequestTypes.COMPLAINT ? (
                        <Typography className="mt-2 font-16-bold d-flex d-flex-align-item-center">
                          {`${translation.selectedOrder} : ${orderDetails[0].OrderNumber}`}
                        </Typography>
                      ) : (
                        <CreateCaseOrderLineSelection
                          orderLines={orderDetails}
                          isNoOrderLinesToAdd={isNoOrderLinesToAdd}
                          selectedOrderLines={selectedOrderLines}
                          translation={translation}
                          showPartNumberReceived={
                            selectedCaseTypeName.toLowerCase() ==
                            CaseRequestTypes.PICK_ERROR
                          }
                          selectedCaseTypeName={selectedCaseTypeName}
                          searchOrderLineError={errors.searchOrderLine}
                          selectedOrderLine={selectedOrderLine}
                          setSelectedOrderLine={setSelectedOrderLine}
                          updateSelectedOrderLines={setSelectedOrderLines}
                        />
                      )}
                    </React.Fragment>
                  )}
                  {(isEditCase ||
                    (selectedOrderLines && selectedOrderLines.length > 0)) && (
                    <React.Fragment>
                      {(selectedCaseTypeName.toLowerCase() ==
                        CaseRequestTypes.RETURN ||
                        selectedCaseTypeName.toLowerCase() ==
                          CaseRequestTypes.PICK_ERROR ||
                        selectedCaseTypeName.toLowerCase() ==
                          CaseRequestTypes.NPSR) && (
                        <React.Fragment>
                          <FormControl className="my-2" fullWidth>
                            <InputLabel htmlFor="return-reason-label">
                              {translation.returnReason}
                            </InputLabel>
                            <Select
                              labelId="return-reason-label"
                              id="return-reason"
                              className="dropdown"
                              name="SubTypeId"
                              value={selectedReturnReason}
                              label={translation.returnReason}
                              fullWidth
                              style={{ minWidth: 120 }}
                              onChange={(e) =>
                                setSelectedReturnReason(
                                  e.target.value as string
                                )
                              }
                              disabled={isEditCase ? true : false}
                            >
                              {returnReasons &&
                                returnReasons.map((item) => {
                                  return (
                                    <MenuItem value={item.Id}>
                                      {getSubCaseType(item, language)}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText
                              error
                              id="search-error"
                              className="helper-text"
                            >
                              {errors.caseSubType}
                            </FormHelperText>
                          </FormControl>
                          <FormControl className="my-2" fullWidth>
                            <InputLabel htmlFor="collection-address">
                              {translation.collectionAddress}
                            </InputLabel>
                            <Select
                              labelId="collection-address-label"
                              id="collection-address"
                              name="CollectionAddressId"
                              className="dropdown"
                              value={selectedCollectionAddress.Id}
                              label={translation.collectionAddress}
                              fullWidth
                              style={{ minWidth: 120 }}
                              onChange={(e) =>
                                handleSelectedAddress(
                                  e.target.value as string,
                                  true
                                )
                              }
                              disabled={isCollectionAddressNotFound}
                            >
                              {addresses &&
                                addresses.map((item) => {
                                  return (
                                    <MenuItem value={item.Id}>
                                      {item.Name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {selectedCollectionAddress.Id !== "" && (
                              <Box className="address-card mt-2">
                                <Typography className="font-16-bold mb-2">
                                  {selectedCollectionAddress.AddressName}
                                </Typography>
                                <p className="font-12-light mb-1">
                                  {selectedCollectionAddress.AddressStreet1 !=
                                    "" &&
                                    `${selectedCollectionAddress.AddressStreet1}, `}
                                  {selectedCollectionAddress.AddressStreet2 !=
                                    "" &&
                                    `${selectedCollectionAddress.AddressStreet2}, `}
                                  {selectedCollectionAddress.AddressStreet3 !=
                                    "" &&
                                    `${selectedCollectionAddress.AddressStreet3}, `}
                                  <br />
                                  {selectedCollectionAddress.AddressCity !=
                                    "" &&
                                    `${selectedCollectionAddress.AddressCity}, `}
                                  {selectedCollectionAddress.AddressPostalCode !=
                                    "" &&
                                    `${selectedCollectionAddress.AddressPostalCode}, `}
                                  {selectedCollectionAddress.AddressCountry !=
                                    "" &&
                                    selectedCollectionAddress.AddressCountry}
                                </p>
                              </Box>
                            )}
                            <FormHelperText
                              error
                              id="search-error"
                              className="helper-text"
                            >
                              {errors.collectionAddress}
                            </FormHelperText>
                          </FormControl>
                          <List className="my-2 gitter-padding">
                            <ListItem>
                              <ListItemText
                                id="collection-address-notfound-label"
                                primary={translation.collectionAddressNotFound}
                              />
                              <Typography>{translation.no}</Typography>
                              <Switch
                                edge="end"
                                checked={isCollectionAddressNotFound}
                                onChange={() =>
                                  setIsCollectionAddressNotFound(
                                    !isCollectionAddressNotFound
                                  )
                                }
                              />
                              <Typography>{translation.yes}</Typography>
                            </ListItem>
                          </List>
                          {isCollectionAddressNotFound && (
                            <Card
                              className="my-2 border-radius-02 p-2 blue-background-opacity"
                              style={{ padding: 8 }}
                            >
                              <TextField
                                id="collection-address-textbox"
                                value={collectionAddress.AddressName}
                                onChange={(e) =>
                                  setCollectionAddress({
                                    ...collectionAddress,
                                    AddressName: e.target.value,
                                  })
                                }
                                label={translation.addressName}
                                name="CollectionAddressName"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.collectionAddressName}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="collection-street1-textbox"
                                value={collectionAddress.AddressLine1}
                                onChange={(e) =>
                                  setCollectionAddress({
                                    ...collectionAddress,
                                    AddressLine1: e.target.value,
                                  })
                                }
                                label={translation.street1}
                                name="CollectionAddressLine1"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.collectionAddressStreet1}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="collection-street2-textbox"
                                value={collectionAddress.AddressLine2}
                                onChange={(e) =>
                                  setCollectionAddress({
                                    ...collectionAddress,
                                    AddressLine2: e.target.value,
                                  })
                                }
                                label={translation.street2}
                                name="CollectionAddressLine2"
                                margin="dense"
                                multiline
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.collectionAddressStreet2}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="collection-street3-textbox"
                                value={collectionAddress.AddressLine3}
                                onChange={(e) =>
                                  setCollectionAddress({
                                    ...collectionAddress,
                                    AddressLine3: e.target.value,
                                  })
                                }
                                label={translation.street3}
                                name="CollectionAddressLine3"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.collectionAddressStreet3}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="collection-pincode-textbox"
                                value={collectionAddress.AddressPostalCode}
                                onChange={(e) =>
                                  setCollectionAddress({
                                    ...collectionAddress,
                                    AddressPostalCode: e.target.value,
                                  })
                                }
                                label={translation.pincode}
                                name="collectionAddressPostalCode"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.collectionAddressPostCode}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                            </Card>
                          )}
                        </React.Fragment>
                      )}
                      {(selectedCaseTypeName.toLowerCase() ==
                        CaseRequestTypes.RETURN ||
                        selectedCaseTypeName.toLowerCase() ==
                          CaseRequestTypes.PICK_ERROR ||
                        selectedCaseTypeName.toLowerCase() ==
                          CaseRequestTypes.NPSR) && (
                        <React.Fragment>
                          <FormControl className="my-2" fullWidth>
                            <InputLabel htmlFor="delivery-address-label">
                              {translation.deliveryAddress}
                            </InputLabel>
                            <Select
                              labelId="delivery-address-label"
                              id="delivery-address"
                              name="deliveryAddressId"
                              value={selectedDeliveryAddress.Id}
                              label={translation.deliveryAddress}
                              fullWidth
                              style={{ minWidth: 120 }}
                              onChange={(e) =>
                                handleSelectedAddress(
                                  e.target.value as string,
                                  false
                                )
                              }
                              disabled={isDeliveryAddressNotFound}
                              className="dropdown"
                            >
                              {addresses &&
                                addresses.map((item) => {
                                  return (
                                    <MenuItem value={item.Id}>
                                      {item.Name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText
                              error
                              id="search-error"
                              className="helper-text"
                            >
                              {errors.deliveryAddress}
                            </FormHelperText>
                          </FormControl>
                          {selectedDeliveryAddress.Id !== "" && (
                            <Box className="address-card mt-2">
                              <Typography className="font-16-bold mb-2">
                                {selectedDeliveryAddress.AddressName}
                              </Typography>
                              <p className="font-12-light mb-1">
                                {selectedDeliveryAddress.AddressStreet1 != "" &&
                                  `${selectedDeliveryAddress.AddressStreet1}, `}
                                {selectedDeliveryAddress.AddressStreet2 != "" &&
                                  `${selectedDeliveryAddress.AddressStreet2}, `}
                                {selectedDeliveryAddress.AddressStreet3 != "" &&
                                  `${selectedDeliveryAddress.AddressStreet3}, `}
                                <br />
                                {selectedDeliveryAddress.AddressCity != "" &&
                                  `${selectedDeliveryAddress.AddressCity}, `}
                                {selectedDeliveryAddress.AddressPostalCode !=
                                  "" &&
                                  `${selectedDeliveryAddress.AddressPostalCode}, `}
                                {selectedDeliveryAddress.AddressCountry != "" &&
                                  selectedDeliveryAddress.AddressCountry}
                              </p>
                            </Box>
                          )}
                          <List className="my-2 gitter-padding">
                            <ListItem>
                              <ListItemText
                                id="collection-address-notfound-label"
                                primary={translation.deliveryAddressNotFound}
                              />
                              <Typography>{translation.no}</Typography>
                              <Switch
                                edge="end"
                                checked={isDeliveryAddressNotFound}
                                onChange={() =>
                                  setIsDeliveryAddressNotFound(
                                    !isDeliveryAddressNotFound
                                  )
                                }
                              />
                              <Typography>{translation.yes}</Typography>
                            </ListItem>
                          </List>
                          {isDeliveryAddressNotFound && (
                            <Card className="my-2" style={{ padding: 8 }}>
                              <TextField
                                id="delivery-address-textbox"
                                value={deliveryAddress.AddressName}
                                onChange={(e) =>
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    AddressName: e.target.value,
                                  })
                                }
                                label={translation.addressName}
                                name="deliveryAddressName"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.deliveryAddressName}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="delivery-street1-textbox"
                                value={deliveryAddress.AddressLine1}
                                onChange={(e) =>
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    AddressLine1: e.target.value,
                                  })
                                }
                                label={translation.street1}
                                name="deliveryAddressLine1"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.deliveryAddressStreet1}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="delivery-street2-textbox"
                                value={deliveryAddress.AddressLine2}
                                onChange={(e) =>
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    AddressLine2: e.target.value,
                                  })
                                }
                                label={translation.street2}
                                name="deliveryAddressLine2"
                                margin="dense"
                                multiline
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.deliveryAddressStreet2}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="delivery-street3-textbox"
                                value={deliveryAddress.AddressLine3}
                                onChange={(e) =>
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    AddressLine3: e.target.value,
                                  })
                                }
                                label={translation.street3}
                                name="deliveryAddressLine3"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.deliveryAddressStreet3}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                              <TextField
                                id="delivery-pincode-textbox"
                                value={deliveryAddress.AddressPostalCode}
                                onChange={(e) =>
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    AddressPostalCode: e.target.value,
                                  })
                                }
                                label={translation.pincode}
                                name="deliveryAddressPostalCode"
                                margin="dense"
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                helperText={errors.deliveryAddressPostCode}
                                FormHelperTextProps={{
                                  classes: {
                                    root: "helper-text",
                                  },
                                }}
                              />
                            </Card>
                          )}
                        </React.Fragment>
                      )}
                      {selectedCaseTypeName.toLowerCase() ==
                        CaseRequestTypes.NPSR && (
                        <React.Fragment>
                          <List className="my-2 gitter-padding">
                            <ListItem>
                              <ListItemText
                                id="replacement-required-lbl"
                                primary={translation.replacementRequired}
                              />
                              <Typography>{translation.no}</Typography>
                              <Switch
                                edge="end"
                                checked={isReplacementRequired}
                                onChange={() =>
                                  setIsReplacementRequired(
                                    !isReplacementRequired
                                  )
                                }
                              />
                              <Typography>{translation.yes}</Typography>
                            </ListItem>
                          </List>
                          <TextField
                            id="seriesLetter"
                            label={translation.seriesLetter}
                            name="serialNumber"
                            value={serialNumber}
                            onChange={(e) =>
                              setSerialNumber(e.target.value as string)
                            }
                            focused={isEditCase ? true : false}
                            className="my-2"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            helperText={errors.serialNumber}
                            FormHelperTextProps={{
                              classes: {
                                root: "helper-text",
                              },
                            }}
                          />
                          <TextField
                            id="faultDetails"
                            label={translation.faultDetails}
                            value={faultDetails}
                            onChange={(e) =>
                              setfaultDetails(e.target.value as string)
                            }
                            name="faultDetails"
                            focused={isEditCase ? true : false}
                            className="my-2"
                            fullWidth
                            multiline
                            inputProps={{ maxLength: 1000 }}
                            helperText={errors.faultDetails}
                            FormHelperTextProps={{
                              classes: {
                                root: "helper-text",
                              },
                            }}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
                {selectedCaseTypeName.toLowerCase() ==
                  CaseRequestTypes.RETURN && (
                  <FormControl className="my-2" fullWidth>
                    <InputLabel htmlFor="conditionOfGoods">
                      {translation.conditionOfGoods}
                    </InputLabel>
                    <Select
                      labelId="conditionOfGoods-label"
                      id="conditionOfGoods"
                      name="conditionOfGoods"
                      className="dropdown"
                      value={selectedConditionOfGoods}
                      label={translation.conditionOfGoods}
                      fullWidth
                      style={{ minWidth: 120 }}
                      onChange={(e) =>
                        setSelectedConditionOfGoods(e.target.value as string)
                      }
                    >
                      {conditionOfGoodsOptions &&
                        conditionOfGoodsOptions.map((item) => {
                          return (
                            <MenuItem value={item.Id}>
                              {getConditionOfGoods(item.Name, language)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText
                      error
                      id="conditionOfGoods-error"
                      className="helper-text"
                    >
                      {errors.conditionOfGoods}
                    </FormHelperText>
                  </FormControl>
                )}
                <TextField
                  id="description"
                  label={translation.additionalDetails}
                  value={description}
                  onChange={(e) => setDescription(e.target.value as string)}
                  focused={isEditCase ? true : false}
                  name="description"
                  className="my-2"
                  multiline
                  fullWidth
                  inputProps={{ maxLength: 2000 }}
                  helperText={description == "" && errors.additionalDetails}
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                />
                {(selectedCaseTypeName.toLowerCase() ==
                  CaseRequestTypes.RETURN ||
                  selectedCaseTypeName.toLowerCase() ==
                    CaseRequestTypes.PICK_ERROR ||
                  selectedCaseTypeName.toLowerCase() ==
                    CaseRequestTypes.NPSR) && (
                  <Box className="d-flex mt-3">
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-file"
                    >
                      {translation.addAttachments}
                      <input
                        type="file"
                        onChange={(e) => handleFilesAdded(e)}
                        hidden
                      />
                    </Button>
                    {files && (
                      <Typography className="font-16-bold ml-3">
                        {files[0].name}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent:
                  selectedCaseTypeName.toLowerCase() ==
                    CaseRequestTypes.RETURN ||
                  selectedCaseTypeName.toLowerCase() ==
                    CaseRequestTypes.PICK_ERROR ||
                  selectedCaseTypeName.toLowerCase() == CaseRequestTypes.NPSR
                    ? "space-between"
                    : "flex-end",
              }}
            >
              {(selectedCaseTypeName.toLowerCase() == CaseRequestTypes.RETURN ||
                selectedCaseTypeName.toLowerCase() ==
                  CaseRequestTypes.PICK_ERROR ||
                selectedCaseTypeName.toLowerCase() ==
                  CaseRequestTypes.NPSR) && (
                <Box>
                  <Checkbox
                    name="AcceptReturnPolicy"
                    value={isAcceptReturnPolicy}
                    onClick={() =>
                      setIsAcceptReturnPolicy(!isAcceptReturnPolicy)
                    }
                    color="primary"
                    checked={isAcceptReturnPolicy}
                  />
                  <a
                    href={getReturnPolicyUrl(language, country)}
                    className="ml-1"
                    target="_blank"
                  >
                    {translation.returnTerms}
                  </a>
                </Box>
              )}
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  className="ms-auto btn-button btn-blue p-2 px-3 mr-3"
                  disabled={
                    !isAcceptReturnPolicy &&
                    selectedCaseTypeName.toLowerCase() !==
                      CaseRequestTypes.COMPLAINT
                  }
                >
                  {isEditCase ? translation.update : translation.create}
                </Button>
                <Button
                  variant="contained"
                  className="ms-auto btn-button btn-blue p-2 px-3 btn-cancel"
                  onClick={handleCaseCreationClose}
                >
                  {translation.cancel}
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </React.Fragment>
  );
};

export default connector(CreateCase);
