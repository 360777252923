import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  ListItem,
  List,
  ListItemText,
  Backdrop,
} from "@material-ui/core";
import * as React from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState, User } from "../../types/Master";
import "../index.css";
import {
  ChangePassword,
  GetUserVerificationStatus,
  ResendVerification,
  UpdateContact,
} from "../../services/CustomerService";
import ToastMessage from "../shared/ToastMessage";
import validator from "validator";

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    updateUser: (val: User) => dispatch({ type: "UPDATE_USER", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
  translation: state.translation,
  language: state.language,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class Profile extends React.Component<PropsFromRedux> {
  state: {
    firstname: string;
    lastname: string;
    email: string;
    mobilePhone: string;
    businessPhone: string;
    jobTitle: string;
    accountName: string;
    isSucceeded: boolean;
    message: string;
    password: string;
    confirm_password: string;
    errors: string;
    signout: boolean;
    mobileValidationMessage: string;
    selectedTab: number;
    editProfile: boolean;
  } = {
    firstname: this.props.user.FirstName,
    lastname: this.props.user.LastName,
    email: this.props.user.Email,
    mobilePhone: this.props.user.MobilePhone,
    businessPhone: this.props.user.BusinessPhone,
    jobTitle: this.props.user.JobTitle,
    accountName: this.props.user.AccountName,
    isSucceeded: false,
    message: "",
    password: "",
    confirm_password: "",
    errors: "",
    signout: false,
    mobileValidationMessage: "",
    selectedTab: 0,
    editProfile: false,
  };

  tabs = [
    { name: this.props.translation.myDetails },
    { name: this.props.translation.changePassword },
  ];

  onFirstNameChange = (e: any) => {
    this.setState({ firstname: e.target.value });
  };

  onLastNameChange = (e: any) => {
    this.setState({ lastname: e.target.value });
  };

  onEmailChange = (e: any) => {
    this.setState({ email: e.target.value });
  };

  onMobilePhoneChange = (e: any) => {
    this.setState({ mobilePhone: e.target.value });
  };

  onBusinessPhoneChange = (e: any) => {
    this.setState({ businessPhone: e.target.value });
  };

  UpdateData = () => {
    this.setState({
      firstname: this.props.user.FirstName,
      lastname: this.props.user.LastName,
      email: this.props.user.Email,
      mobilePhone: this.props.user.MobilePhone,
    });
  };

  sendVerifyMail = () => {
    this.props.setLoader(true);
    ResendVerification(this.props.user.ID)
      .then(() => {
        this.props.setLoader(false);
        this.setState({
          message: this.props.translation.profileVerificationSuccess,
          isSucceeded: true,
        });
      })
      .catch((ex) => {
        this.props.setLoader(false);
        this.setState({
          message: this.props.translation.profileVerificationFail,
          isSucceeded: false,
        });
      });
  };

  save = () => {
    let userObj = { ...this.props.user };
    userObj.ID = this.props.user.ID;
    userObj.FirstName = this.state.firstname;
    userObj.LastName = this.state.lastname;
    userObj.Email = this.state.email;
    userObj.MobilePhone = this.state.mobilePhone;
    if (this.validateUpdate(userObj.MobilePhone)) {
      this.props.setLoader(true);
      UpdateContact(
        userObj.ID,
        userObj.FirstName,
        userObj.LastName,
        userObj.Email,
        userObj.MobilePhone
      )
        .then((response) => {
          this.props.setLoader(false);
          if (response.status == "success") {
            this.setState({
              message: this.props.translation.profileUpdateSuccess,
              isSucceeded: true,
              editProfile: false,
            });
            this.props.updateUser(userObj);
          } else {
            this.setState({
              message: this.props.translation.profileUpdateFail,
              isSucceeded: false,
              editProfile: false,
            });
            this.UpdateData();
          }
        })
        .catch((ex) => {
          this.props.setLoader(false);
          this.UpdateData();
          this.setState({
            message: this.props.translation.profileUpdateFail,
            isSucceeded: false,
            editProfile: false,
          });
        });
    }
  };

  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
  };

  handleConfirmation = (e: any) => {
    this.setState({ confirm_password: e.target.value });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (this.validate()) {
      this.props.setLoader(true);
      data.append("userId", this.props.user.ID);
      ChangePassword(data)
        .then((response) => {
          if (response.status == "success") {
            this.props.setLoader(false);
            this.props.setToastMessage(
              this.props.translation.passwordUpdateSuccess,
              true
            );
            this.setState({ signout: true });
          } else {
            this.props.setLoader(false);
            this.setState({
              message: this.props.translation.passwordUpdateFail,
              isSucceeded: false,
              signout: false,
            });
          }
        })
        .catch((ex) => {
          this.props.setLoader(false);
          this.setState({
            message: this.props.translation.passwordUpdateFail,
            isSucceeded: false,
            signout: false,
          });
        });
    }
  };

  validateUpdate = (mobileNo: any) => {
    let isValid = true;
    this.setState({
      mobileValidationMessage: "",
    });
    if (mobileNo.length != 10) {
      this.setState({
        mobileValidationMessage: this.props.translation.mobileValidationMsg,
      });
      isValid = false;
    }
    return isValid;
  };

  validate = () => {
    let pwd = this.state.password;
    let confirm_pwd = this.state.confirm_password;
    let errors = "";
    let isValid = true;

    if (typeof pwd !== "undefined" && typeof confirm_pwd !== "undefined") {
      if (pwd != confirm_pwd) {
        isValid = false;
        errors = this.props.translation.passwordMismatch;
      }
    }

    if (
      !validator.isStrongPassword(pwd, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      isValid = false;
      errors = this.props.translation.passwordPolicyMessage;
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  };

  handleClose = () => {
    this.setState({
      message: "",
      isSucceeded: false,
    });
  };

  componentDidMount() {
    this.props.setNavigation("/profile");
    this.props.setLoader(true);
    GetUserVerificationStatus(this.props.user.ID, this.props.language)
      .then((message) => {
        this.props.setLoader(false);
        this.setState({
          message,
          isSucceeded: false,
        });
      })
      .catch(() => {
        this.props.setLoader(false);
      });
  }

  render() {
    const { isLoading, logout, translation } = this.props;

    if (this.state.signout) {
      logout();
    }

    return (
      <Box className="d-flex d-flex-direction-column">
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <Box className="loading-icon" />
        </Backdrop>
        <ToastMessage
          isOpen={this.state.message != ""}
          variant={
            this.state.isSucceeded
              ? "success"
              : this.state.message != ""
              ? "danger"
              : ""
          }
          message={this.state.message}
          handleToastClose={this.handleClose}
        />
        <Box className="page-title-background">
          <Box className="container">
            <h2 className="color-green">{translation.myProfile}</h2>
          </Box>
        </Box>
        <Box className="container">
          <Box className="mt-4 row d-flex">
            <Box className="col-xs-12 col-md-3">
              <Box className="table-styling text-center p-3">
                <Box className="mb-3 profile-icon">
                  <img src={require("../../images/profile-icon.png")} />
                </Box>
                <Box className="mb-2 w-100 font-16-normal light-grey-color-02">
                  <Typography className="font-20-bold">
                    {`${this.state.firstname} ${this.state.lastname}`}
                  </Typography>
                </Box>
                <Grid item xs={12} className="myaccount-left-navigation">
                  <List>
                    {this.tabs.map((tab, index) => {
                      return (
                        <ListItem
                          className={`nav-item text-center ${
                            index == this.state.selectedTab ? "active" : ""
                          }`}
                          onClick={() => this.setState({ selectedTab: index })}
                        >
                          <ListItemText primary={tab.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Box>
            </Box>
            {this.state.selectedTab == 0 && (
              <Box className="myaccount-wrapper col-xs-12 col-md-9">
                <Box className="table-styling p-3">
                  <Box className="pb-2">
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      fullWidth
                      id="firstName"
                      label={translation.firstName}
                      autoFocus
                      onChange={this.onFirstNameChange}
                      disabled={!this.state.editProfile}
                      value={this.state.firstname}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      autoComplete="given-name"
                      name="lastName"
                      fullWidth
                      id="lastName"
                      label={translation.lastName}
                      onChange={this.onLastNameChange}
                      disabled={!this.state.editProfile}
                      value={this.state.lastname}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label={translation.jobTitle}
                      name="jobTitle"
                      disabled
                      value={this.state.jobTitle}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="accountname"
                      label={translation.account}
                      name="account"
                      disabled
                      value={this.state.accountName}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label={translation.emailAddress}
                      name="email"
                      autoComplete="email"
                      disabled={true}
                      value={this.state.email}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      name="mobilePhone"
                      fullWidth
                      id="mobilePhone"
                      label={translation.mobilePhone}
                      autoFocus
                      onChange={this.onMobilePhoneChange}
                      disabled={!this.state.editProfile}
                      value={this.state.mobilePhone}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                      helperText={this.state.mobileValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "helper-text",
                        },
                      }}
                    />
                  </Box>
                  <Box className="pb-2">
                    <TextField
                      name="businessPhone"
                      fullWidth
                      id="businessPhone"
                      label={translation.businessPhone}
                      autoFocus
                      onChange={this.onBusinessPhoneChange}
                      disabled={!this.state.editProfile}
                      value={this.state.businessPhone}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                      helperText={this.state.mobileValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "helper-text",
                        },
                      }}
                    />
                  </Box>
                  {!this.props.user.IsVerified && (
                    <Box className="d-flex-justifyContent-end d-flex mt-2">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="ms-auto btn-button btn-blue p-2 px-3 mt-2"
                        onClick={this.sendVerifyMail}
                      >
                        {translation.sendVerifyMail}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {this.state.selectedTab == 1 && (
              <Box
                className="myAccount-wrapper col-xs-12 col-md-9"
                component="form"
                onSubmit={this.handleSubmit}
              >
                <Box className="table-styling p-3">
                  <TextField
                    autoFocus
                    type="password"
                    className="pb-2"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={translation.currentPassword}
                    name="password"
                  />
                  <TextField
                    type="password"
                    className="pb-2"
                    margin="normal"
                    required
                    fullWidth
                    id="new_password"
                    label={translation.newPassword}
                    name="new_password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                  <TextField
                    type="password"
                    className="pb-2"
                    name="updatedPassword"
                    required
                    fullWidth
                    id="confirm_password"
                    label={translation.confirmPassword}
                    value={this.state.confirm_password}
                    onChange={this.handleConfirmation}
                    helperText={this.state.errors}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                  />
                  <Box className="d-flex-justifyContent-end d-flex mt-2">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={this.props.isLoading}
                      className="ms-auto btn-button btn-blue p-2 px-3 mt-2"
                    >
                      {translation.updatePassword}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default connector(Profile);
