import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import { ApplicationState } from "./types/Master";

const AuthRoute = (props: any) => {
  const { isAuthUser, type } = props;
  if (type === "guest" && isAuthUser) return <Redirect to="/home" />;
  else if (type === "private" && !isAuthUser) return <Redirect to="/" />;
  return <Route {...props} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthUser: state.isAuthUser,
});

export default connect(mapStateToProps)(AuthRoute);
