import gb from "./gb";
import be from "./be";
import at from "./at";
import nl from "./nl";

export default {
  gb,
  be,
  at,
  nl,
};
