export interface ApplicationState {
  isAuthUser: boolean;
  user: User;
  isLoading: boolean;
  error: any;
  selectedNavItem: string;
  toastMessage: string;
  isToastMessageSuccess: boolean;
  editCaseNumer: string;
  language: string;
  country: string;
  translation: any;
}

export interface SignInRequest {
  id: string;
  password: string;
}

export interface User {
  ID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Permissions: Permission[];
  AccountId: string;
  AccountName: string;
  MobilePhone: string;
  BusinessPhone: string;
  JobTitle: string;
  IsVerified: boolean;
  Token: string;
}

export interface Order {
  Id: string;
  OrderNumber: string;
  OrderDate: string;
  Name: string;
  TotalAmount: string;
  StatusCode: string;
  TaxAmount: string;
  DiscountAmount: string;
  BillingStreet: string;
  BillingCity: string;
  BillingState: string;
  BillingCountry: string;
  BillingZip: string;
  ShippingStreet: string;
  ShippingCity: string;
  ShippingState: string;
  ShippingCountry: string;
  ShippingZip: string;
}

export interface OrderLine {
  Id: string;
  OrderId: string;
  OrderDetailId: string;
  OrderNumber: string;
  OrderDate: string;
  PricePerUnit: string;
  ProductID: string;
  Description: string;
  Quantity: string;
  ToBeReturnedQty: string;
  ReturnQty: string;
  isExistingReturnItem: boolean;
  ProductNumber: string;
  ProductNumberReceived: string;
}

export interface Address {
  AddressName: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressPostalCode: string;
}

export interface Case {
  ID: string;
  Index: number;
  CustomerId: string;
  OwnerId: string;
  OwnerType: string;
  Title: string;
  TicketNumber: string;
  CreatedOn: string;
  CaseOriginCode: string;
  SubjectId: string;
  StatusCode: string;
  StateCode: string;
  Suffix: string;
  Description: string;
  Attachment: string;
  Comments: string;
  Type: string;
  TypeId: string;
  SubType: string;
  SubTypeId: string;
  FileName: string;
  IsCollectionAddressNotFound: boolean;
  CollectionAddressSite: string;
  CollectionAddress: Address;
  IsDeliveryAddressNotFound: boolean;
  DeliveryAddressSite: string;
  DeliveryAddress: Address;
  SalesOrderLines: CaseSalesOrderLine[];
  OrderId: string;
  OrderNumber: string;
  IsReplecementRequired: boolean;
  FaultDetails: string;
  SerialNumber: string;
  Contact: string;
  AcceptReturnPolicy: boolean;
  ConditionOfGoods: string;
}

export interface CaseSalesOrderLine {
  OrderLineId: string;
  OrderDetailId: string;
  ProductName: string;
  ProductQuantity: string;
  ProductNumberReceived: string;
}

export interface Registration {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  TelComercial: string;
  TelCelular: string;
}

export interface article {
  PublicNumber: string;
  Id: any;
  Title: string;
  CreatedDate: any;
  MajorVersionNumber: number;
  MinorVersionNumber: number;
  Content: string;
}

export interface Permission {
  Entityname: string;
  CanRead: boolean;
  CanCreate: boolean;
  CanUpdate: boolean;
}

export interface Survey {
  Name: string;
  LinkTo: string;
  InviteSent: string;
  OrderNumber: string;
}

export interface CaseType {
  Id: string;
  Name: string;
  NameAustria: string;
  NameBelgium: string;
  NameNetherlands: string;
}

export interface CaseSubType {
  Id: string;
  Name: string;
  NameAustria: string;
  NameBelgium: string;
  NameNetherlands: string;
  TypeId: string;
}

export interface ConditionOfGoods {
  Id: string;
  Name: string;
}

export interface OpCoCountry {
  Id: string;
  Name: string;
}

export interface CustomerAddress {
  Id: string;
  Name: string;
  AddressName: string;
  AddressStreet1: string;
  AddressStreet2: string;
  AddressStreet3: string;
  AddressCity: string;
  AddressCountry: string;
  AddressPostalCode: string;
}

export interface errorValidation {
  caseType: string;
  searchOrder: string;
  searchOrderLine: string;
  caseSubType: string;
  collectionAddress: string;
  collectionAddressName: string;
  collectionAddressStreet1: string;
  collectionAddressStreet2: string;
  collectionAddressStreet3: string;
  collectionAddressPostCode: string;
  deliveryAddress: string;
  deliveryAddressName: string;
  deliveryAddressStreet1: string;
  deliveryAddressStreet2: string;
  deliveryAddressStreet3: string;
  deliveryAddressPostCode: string;
  additionalDetails: string;
  serialNumber: string;
  faultDetails: string;
  conditionOfGoods: string;
  suffix: string;
}

//Default values
export const initialUserState = {
  ID: "",
  FirstName: "",
  LastName: "",
  Email: "",
  Permissions: [],
  AccountId: "",
  AccountName: "",
  BusinessPhone: "",
  MobilePhone: "",
  JobTitle: "",
  IsVerified: false,
  Token: "",
};

export const defaultAddress = {
  AddressName: "",
  AddressLine1: "",
  AddressLine2: "",
  AddressLine3: "",
  AddressPostalCode: "",
};

export const defaultCustomerAddress = {
  Id: "",
  Name: "",
  AddressName: "",
  AddressStreet1: "",
  AddressStreet2: "",
  AddressStreet3: "",
  AddressCity: "",
  AddressCountry: "",
  AddressPostalCode: "",
};

export const initialCaseState = {
  ID: "",
  Index: 0,
  CustomerId: "",
  OwnerId: "",
  OwnerType: "",
  Title: "",
  TicketNumber: "",
  CreatedOn: "",
  CaseOriginCode: "",
  SubjectId: "",
  StatusCode: "",
  StateCode: "",
  Description: "",
  Suffix: "",
  Attachment: "",
  Comments: "",
  Type: "",
  TypeId: "",
  SubType: "",
  SubTypeId: "",
  FileName: "",
  IsCollectionAddressNotFound: false,
  CollectionAddressSite: "",
  CollectionAddress: defaultAddress,
  IsDeliveryAddressNotFound: false,
  DeliveryAddressSite: "",
  DeliveryAddress: defaultAddress,
  SalesOrderLines: [],
  OrderId: "",
  OrderNumber: "",
  IsReplecementRequired: false,
  FaultDetails: "",
  SerialNumber: "",
  Contact: "",
  AcceptReturnPolicy: false,
  ConditionOfGoods: "",
};

export const defaultOrderLine = {
  Id: "",
  OrderId: "",
  OrderDetailId: "",
  OrderNumber: "",
  OrderDate: "",
  PricePerUnit: "",
  ProductID: "",
  Description: "",
  Quantity: "",
  ToBeReturnedQty: "0",
  ReturnQty: "",
  isExistingReturnItem: false,
  ProductNumber: "",
  ProductNumberReceived: "",
};

export const mimeDict = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx: "application/msword",
  xlsx: "application/vnd.ms-excel",
  png: "image/png",
  jpeg: "image/jpeg",
  gif: "image/gif",
  rar: "application/zip",
};

export const defaultPermission = {
  Entityname: "",
  CanRead: false,
  CanCreate: false,
  CanUpdate: false,
};

export const defaultErrorValidation = {
  caseType: "",
  searchOrder: "",
  searchOrderLine: "",
  caseSubType: "",
  collectionAddress: "",
  collectionAddressName: "",
  collectionAddressStreet1: "",
  collectionAddressStreet2: "",
  collectionAddressStreet3: "",
  collectionAddressPostCode: "",
  deliveryAddress: "",
  deliveryAddressName: "",
  deliveryAddressStreet1: "",
  deliveryAddressStreet2: "",
  deliveryAddressStreet3: "",
  deliveryAddressPostCode: "",
  additionalDetails: "",
  serialNumber: "",
  faultDetails: "",
  conditionOfGoods: "",
  suffix: "",
};

export const defaultCaseType = {
  Id: "",
  Name: "",
  NameAustria: "",
  NameBelgium: "",
  NameNetherlands: "",
};
