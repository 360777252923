import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Cancel, HelpOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { useState } from "react";
import { CaseRequestTypes } from "../../services/Shared";
import { OrderLine } from "../../types/Master";
import { StyledTableRow } from "../shared/Table";
interface CreateCaseOrderLineSelectionProps {
  orderLines: OrderLine[];
  isNoOrderLinesToAdd: boolean;
  selectedOrderLines: OrderLine[];
  translation: any;
  showPartNumberReceived: boolean;
  selectedCaseTypeName: string;
  searchOrderLineError: string;
  selectedOrderLine: OrderLine;
  setSelectedOrderLine: (val: OrderLine) => void;
  updateSelectedOrderLines: (val: OrderLine[]) => void;
}

const CreateCaseOrderLineSelection = (
  props: CreateCaseOrderLineSelectionProps
) => {
  const {
    orderLines,
    isNoOrderLinesToAdd,
    selectedOrderLines,
    translation,
    showPartNumberReceived,
    selectedCaseTypeName,
    searchOrderLineError,
    selectedOrderLine,
    setSelectedOrderLine,
    updateSelectedOrderLines,
  } = props;

  const [qtyValidationText, setQtyValidationText] = React.useState<string>("");

  const handleSelectedOrderLines = (val: OrderLine[]) => {
    updateSelectedOrderLines(val);
  };

  const handleSelectedOrderLine = (value: string) => {
    let tempOrderLines = [...orderLines];
    tempOrderLines.every((item) => {
      if (item.OrderDetailId == value) {
        setSelectedOrderLine(item);
        return false;
      } else return true;
    });
  };

  const addSelectedOrderLine = () => {
    if (selectedOrderLine) {
      let tempSelectedOrderLine = {
        ...selectedOrderLine,
        ToBeReturnedQty: "",
      };
      let isItemAlreadyAdded = false;
      let tempSelectedOrderLines = [...selectedOrderLines];
      tempSelectedOrderLines.every((item) => {
        if (item.OrderDetailId == tempSelectedOrderLine.OrderDetailId) {
          isItemAlreadyAdded = true;
          return false;
        } else return true;
      });
      if (!isItemAlreadyAdded)
        tempSelectedOrderLines.push(tempSelectedOrderLine);
      handleSelectedOrderLines(tempSelectedOrderLines);
    }
  };

  const handleQtyChange = (qty: string, itemId: string) => {
    let tempSelectedOrderLines = [...selectedOrderLines];
    tempSelectedOrderLines.every((item) => {
      if (item.OrderDetailId == itemId) {
        if (selectedCaseTypeName.toLowerCase() == CaseRequestTypes.PICK_ERROR) {
          item.ToBeReturnedQty = qty;
          setQtyValidationText("");
        } else if (parseInt(qty) <= parseInt(item.Quantity)) {
          item.ToBeReturnedQty = qty;
          setQtyValidationText("");
        } else if (qty == "") {
          item.ToBeReturnedQty = qty;
          setQtyValidationText("");
        } else {
          setQtyValidationText(translation.quantityValidation);
          setTimeout(() => setQtyValidationText(""), 4000);
        }
        return false;
      } else return true;
    });
    handleSelectedOrderLines(tempSelectedOrderLines);
  };

  const handleClear = (itemId: string) => {
    let tempSelectedOrderLines = [...selectedOrderLines];
    var i = tempSelectedOrderLines.length;
    while (i--) {
      if (tempSelectedOrderLines[i].OrderDetailId === itemId) {
        tempSelectedOrderLines.splice(i, 1);
      }
    }
    handleSelectedOrderLines(tempSelectedOrderLines);
  };

  const handlePartNumberReceievedChange = (val: string, itemId: string) => {
    let tempSelectedOrderLines = [...selectedOrderLines];
    tempSelectedOrderLines.every((item) => {
      if (item.OrderDetailId == itemId) {
        item.ProductNumberReceived = val;
        return false;
      } else return true;
    });
    handleSelectedOrderLines(tempSelectedOrderLines);
  };

  const addToolTip = (endAdornment: any) => {
    const children = React.Children.toArray(endAdornment.props.children);
    children.push(
      <Tooltip
        title={
          <Typography className="tooltip-text" style={{ color: "white" }}>
            {translation.selectOrderLineHelp}
          </Typography>
        }
      >
        <HelpOutlined className="tooltip-help ml-2" />
      </Tooltip>
    );
    return React.cloneElement(endAdornment, {}, children);
  };

  return (
    <Box className="my-2">
      <Box className="table-round-top-wrapper ">
        <Box className="d-flex d-flex-justifyContent-space-between">
          <Typography className="font-16-bold d-flex d-flex-align-item-center">
            {`${translation.orderNumber} : ${orderLines[0].OrderNumber}`}
          </Typography>
        </Box>
        <FormControl className="my-2" fullWidth>
          <Autocomplete
            value={selectedOrderLine && selectedOrderLine}
            onChange={(event, newValue) => {
              handleSelectedOrderLine(newValue ? newValue.OrderDetailId : "");
            }}
            id="select-order-lines"
            options={orderLines}
            getOptionLabel={(option) =>
              option.ProductNumber ? option.ProductNumber : option.ProductID
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={translation.selectOrderLine}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: addToolTip(params.InputProps.endAdornment),
                }}
              />
            )}
            disabled={isNoOrderLinesToAdd}
          />
          <FormHelperText error id="search-error" className="helper-text">
            {searchOrderLineError}
          </FormHelperText>
        </FormControl>
        <Button
          variant="contained"
          className="ms-auto btn-button btn-blue p-2 px-3 my-2"
          onClick={() => addSelectedOrderLine()}
          disabled={isNoOrderLinesToAdd}
        >
          {translation.addSalesLine}
        </Button>
      </Box>
      <Box className="table-round-styling">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translation.partNumber}</TableCell>
                <TableCell>{translation.quantity}</TableCell>
                {showPartNumberReceived && (
                  <TableCell>{translation.partNumberReceieved}</TableCell>
                )}
                <TableCell>
                  {selectedCaseTypeName.toLowerCase() ==
                    CaseRequestTypes.RETURN ||
                  selectedCaseTypeName.toLowerCase() == CaseRequestTypes.NPSR
                    ? translation.returnQty
                    : translation.receivedQty}
                </TableCell>
                {/* <TableCell>{translation.product}</TableCell> */}
                <TableCell>{translation.action}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOrderLines &&
                selectedOrderLines.length > 0 &&
                selectedOrderLines.map((orderLine, index) => {
                  return (
                    <StyledTableRow>
                      <TableCell>{orderLine.ProductNumber}</TableCell>
                      <TableCell>{orderLine.Quantity}</TableCell>
                      {showPartNumberReceived && (
                        <TableCell>
                          <TextField
                            required
                            id="partnumber-text"
                            name="partNumberReceived"
                            margin="dense"
                            fullWidth
                            value={orderLine.ProductNumberReceived}
                            onChange={(e) =>
                              handlePartNumberReceievedChange(
                                e.target.value,
                                orderLine.OrderDetailId
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <TextField
                          required
                          id="quantity-text"
                          name="quantity"
                          margin="dense"
                          value={orderLine.ToBeReturnedQty}
                          onChange={(e) =>
                            handleQtyChange(
                              e.target.value,
                              orderLine.OrderDetailId
                            )
                          }
                          type="number"
                          fullWidth
                          helperText={qtyValidationText}
                          FormHelperTextProps={{
                            classes: {
                              root: "helper-text",
                            },
                          }}
                          inputProps={{
                            maxLength: 2000,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleClear(orderLine.OrderDetailId)}
                        >
                          <Cancel />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CreateCaseOrderLineSelection;
