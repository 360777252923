import { Box, Button, Grid, TextField, Backdrop } from "@material-ui/core";
import * as React from "react";
import "../index.css";
import { CreatePassword } from "../../services/CustomerService";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState } from "../../types/Master";
import { Dispatch } from "redux";

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
  translation: state.translation,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class CreateNewPasssword extends React.Component<PropsFromRedux> {
  state: {
    isRedirect: boolean;
    errors: string;
  } = {
    isRedirect: false,
    errors: "",
  };

  validate = (data: FormData) => {
    let pwd = data.get("password");
    let confirm_pwd = data.get("confirmpassword");
    let isValid = true;
    if (typeof pwd !== "undefined" && typeof confirm_pwd !== "undefined") {
      if (pwd != confirm_pwd) {
        isValid = false;
        this.setState({
          errors: this.props.translation.passwordMismatch,
        });
      }
    }
    return isValid;
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (this.validate(data)) {
      this.props.setLoader(true);
      CreatePassword(data.get("email"), data.get("password"))
        .then(() => {
          this.props.setLoader(false);
          this.props.setToastMessage(
            this.props.translation.passwordUpdateSuccess,
            true
          );
          this.setState({ isRedirect: true });
        })
        .catch((ex) => {
          this.props.setLoader(false);
          this.props.setToastMessage(
            this.props.translation.passwordUpdateFail,
            false
          );
          this.setState({ isRedirect: true });
        });
    }
  };

  render() {
    const { isRedirect } = this.state;
    const { isLoading, translation } = this.props;

    if (isRedirect) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Box className="d-flex d-flex-direction-column mt-4">
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <Box className="loading-icon" />
        </Backdrop>
        <Box
          component="form"
          onSubmit={this.handleSubmit}
          className="mt-4 d-flex left-side-wrapper row d-flex-align-item-center"
        >
          <Box className="col-xs-12 col-sm-6 left-side-main">
            <Box className="left-side-img">
              <img src={require("../../images/create-password-img.png")} />
            </Box>
          </Box>
          <Box className="right-side-text d-flex-justifyContent-end col-xs-12 col-sm-6 text-center">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={translation.emailAddress}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={translation.password}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmpassword"
                  label={translation.confirmPassword}
                  type="password"
                  id="confirmpassword"
                  autoComplete="confirm-password"
                  helperText={this.state.errors}
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box className="d-flex-justifyContent-end d-flex">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={this.props.isLoading}
                className="ms-auto btn-button btn-blue mt-3"
              >
                {translation.submit}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
export default connector(CreateNewPasssword);
