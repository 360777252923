import { Backdrop, Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { GetSurveys } from "../../services/CustomerService";
import { getDatetime } from "../../services/Shared";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState, Survey } from "../../types/Master";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  translation: state.translation,
});

const mapDispatchToProps = (dispatch: React.Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setNavigation: (val: string) =>
      dispatch({ type: "SET_NAVIGATION", payload: val }),
  };
};

const Surveys = (props: any) => {
  const { isLoading, user, setLoader, setNavigation, translation } = props;
  const [surveys, setSurveys] = React.useState<Survey[]>([]);

  useEffect(() => {
    setNavigation("/surveys");
    setLoader(true);
    GetSurveys(user.ID)
      .then((res) => {
        setLoader(false);
        if (res) setSurveys(res);
      })
      .catch((ex) => {
        setLoader(false);
        console.error(ex);
      });
  }, []);

  return (
    <Box className="d-flex d-flex-direction-column">
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <Box className="loading-icon" />
      </Backdrop>
      <Box className="page-title-background">
        <Box className="container">
          <h2 className="color-green">{translation.surveys}</h2>
        </Box>
      </Box>
      <Box className="container">
        <Box className="white-background-main mt-5">
          {surveys && surveys.length > 0 ? (
            surveys.map((survey) => {
              return (
                <Box className="p-2">
                  <Box className="border-bottom-grey-03 pb-3 d-flex d-flex-align-item-center d-flex-justifyContent-space-between">
                    <Box>
                      <Typography className="font-16-bold">
                        {survey.Name}
                      </Typography>
                      <Typography className="font-14-light mt-1">{`Invitation Sent Date : ${getDatetime(
                        survey.InviteSent
                      )}`}</Typography>
                      {survey.OrderNumber != "" && (
                        <Typography className="font-14-light">{`Order Number : ${survey.OrderNumber}`}</Typography>
                      )}
                    </Box>
                    <a
                      target="_blank"
                      href={survey.LinkTo}
                      className="survey-button"
                    >
                      {translation.completeSurvey}
                    </a>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography>{translation.noSurvey}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
