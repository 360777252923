import { Box, Button, Grid, TextField, Backdrop } from "@material-ui/core";
import * as React from "react";
import "../index.css";
import { ForgotPassword } from "../../services/CustomerService";
import { ApplicationState } from "../../types/Master";
import { action_types } from "../../types/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";
import ToastMessage from "../shared/ToastMessage";

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (message: string, status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { message, status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
  translation: state.translation,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
class ForgetPassword extends React.Component<PropsFromRedux> {
  state: {
    isResetPasswordSucceeded: boolean;
    isResetPasswordFailed: boolean;
  } = {
    isResetPasswordSucceeded: false,
    isResetPasswordFailed: false,
  };

  handleLoginAlert = () => {
    this.setState({ isValid: false });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.setLoader(true);
    const data = new FormData(event.currentTarget);
    ForgotPassword(data.get("email"))
      .then(() => {
        this.props.setLoader(false);
        this.props.setToastMessage(
          this.props.translation.forgetPasswordLinkSuccess,
          true
        );
        this.setState({ isResetPasswordSucceeded: true });
      })
      .catch((ex) => {
        this.props.setLoader(false);
        this.setState({ isResetPasswordFailed: true });
      });
  };

  handleClose = () => {
    this.setState({ isResetPasswordFailed: false });
  };

  render() {
    const { isResetPasswordSucceeded, isResetPasswordFailed } = this.state;
    const { isLoading, translation } = this.props;

    if (isResetPasswordSucceeded) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Box className="container">
        <Box className="d-flex left-side-wrapper row d-flex-align-item-center min-height-300">
          <ToastMessage
            isOpen={isResetPasswordFailed}
            variant="danger"
            message={translation.resetPasswordfailed}
            handleToastClose={this.handleClose}
          />
          <Backdrop
            style={{
              zIndex: 2250,
            }}
            open={isLoading}
          >
            <Box className="loading-icon" />
          </Backdrop>
          <Box className="col-xs-12 col-sm-6 left-side-main">
            <Box className="left-side-img">
              <img src={require("../../images/forgot-password-image.png")} />
            </Box>
          </Box>
          <Box className="right-side-text d-flex-justifyContent-end col-xs-12 col-sm-6 text-center">
            <Box className="mb-3 forgot-password-icon">
              <img src={require("../../images/forgot-password-icon.png")} />
            </Box>
            <h3 className="color-orange">{translation.forgetPassword}</h3>
            <Box
              component="form"
              onSubmit={this.handleSubmit}
              style={{ marginTop: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label={translation.emailAddress}
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
              </Grid>
              <Box className="d-flex-justifyContent-end d-flex">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={this.props.isLoading}
                  className="ms-auto btn-button btn-blue mt-3"
                >
                  {translation.submit}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
export default connector(ForgetPassword);
